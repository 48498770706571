import { createSelector } from 'reselect';

import { issueStatusesMock } from '../../mockData/issues';
import { RootState } from '../rootReducer';

const state = ({ issues }: RootState) => issues;

export const issueSelector = createSelector(state, ({ issue }) => issue);
export const issueActivePutStatusesSelector = createSelector(state, ({ issue }) => {
    if (!issue) return null;
    const { status } = issue;
    return issueStatusesMock[status].activePutStatuses.filter((el) =>
        issueStatusesMock[status].isCanPutActiveStatus(issue, el),
    );
});
export const issuesSelector = createSelector(state, ({ issues }) => issues);
export const issuesFiltersSelector = createSelector(state, ({ filters }) => filters);
export const statusFiltersSelector = createSelector(state, ({ statusFilters }) => statusFilters);
export const tableHeadIssuesSelector = createSelector(state, ({ tableHead }) => tableHead);
export const isIssuesTableLoaderSelector = createSelector(state, ({ isIssuesTableLoader }) => isIssuesTableLoader);
export const issuesPaginationSelector = createSelector(state, ({ pagination }) => pagination);
export const issuesIsOpenCommentDialogSelector = createSelector(
    state,
    ({ isOpenCommentDialog }) => isOpenCommentDialog,
);
export const issuesIsOpenDeleteCommentDialogSelector = createSelector(
    state,
    ({ isOpenDeleteCommentDialog }) => isOpenDeleteCommentDialog,
);
export const selectedIssuesSelector = createSelector(state, ({ selectedIssues }) => selectedIssues);
export const notesIssuesSelector = createSelector(state, ({ notesIssue }) => notesIssue);
