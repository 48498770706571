import { TSuggestedPrice } from 'pages/accounting/deposits/store/types';

import { Table as MuiTable, styled, TableCell, TableBody, TableRow, TableHead } from '@mui/material';
import { FC } from 'react';
import { NumericFormat } from 'react-number-format';

type Props = {
    rows: TSuggestedPrice[];
    issueOid: number;
};

export const Table: FC<Props> = ({ rows, issueOid }) => {
    return (
        <MuiTable>
            <TableHead>
                <TableRow>
                    <HeadCell sx={{ paddingLeft: '2px' }}>НОМЕР ЗАЯВКИ</HeadCell>
                    <HeadCell>ПЛАТЕЛЬЩИК</HeadCell>
                    <HeadCell>ПАРТНЕР</HeadCell>
                    <HeadCell align="center">ТИП УСЛУГИ</HeadCell>
                    <HeadCell align="center">НАИМЕНОВАНИЕ УСЛУГИ</HeadCell>
                    <HeadCell align="center">ЦЕНА УСЛУГИ</HeadCell>
                    <HeadCell align="center">НАЧИСЛЯЕМЫЙ ПРОЦЕНТ</HeadCell>
                    <HeadCell align="center">СУММА НАЧИСЛЕНИЯ</HeadCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {rows.map((row) => {
                    return (
                        <TableRow key={row.id}>
                            <Cell sx={{ paddingLeft: '2px' }}>{issueOid}</Cell>
                            <Cell>
                                {!row.price_recipient ? '-' : row.price_recipient === 'client' ? 'Клиент' : 'Партнер'}
                            </Cell>
                            <Cell>-</Cell>
                            <Cell
                                align="center"
                                sx={{ maxWidth: '200px' }}
                            >
                                {row.price.service.name}
                            </Cell>
                            <Cell align="center">{row.price.name}</Cell>
                            <Cell align="center">
                                <NumericFormat
                                    displayType="text"
                                    value={row.amount}
                                    suffix=" ₽"
                                    thousandSeparator=" "
                                />
                            </Cell>
                            <Cell align="center">
                                {row.commission_type === 'fixation'
                                    ? '-'
                                    : `${Math.floor(row.commission_amount * 100)} %`}
                            </Cell>
                            <Cell align="center">
                                <NumericFormat
                                    displayType="text"
                                    value={
                                        row.commission_type === 'fixation'
                                            ? row.commission_amount
                                            : row.amount * row.commission_amount
                                    }
                                    suffix=" ₽"
                                    thousandSeparator=" "
                                    decimalScale={2}
                                />
                            </Cell>
                        </TableRow>
                    );
                })}
            </TableBody>
        </MuiTable>
    );
};

const Cell = styled(TableCell)({
    fontWeight: '400',
    fontSize: '14px',
});

const HeadCell = styled(TableCell)({
    color: '#9E9E9E',
    fontWeight: '600',
    fontSize: '12px',
});
