import CheckIcon from '@mui/icons-material/Check';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Button, Divider, Popover, styled } from '@mui/material';
import { IFilter } from 'interfaces/filter';
import { Fragment, useEffect, useState } from 'react';

import './FilterAppType.scss';

const WhiteBtn = styled(Button)({
    background: '#fff',
    textTransform: 'none',
    color: '#000',
    ':hover': {
        background: '#ffffff90',
    },
});

interface IProps {
    title: string;
    filterItems: IFilter[];
    onChange: (value: string | null) => void;
    selectedId?: string;
    closeAfterSelect?: boolean;
    unclearable?: boolean;
}

function FilterAppType({ title, filterItems, onChange, selectedId, closeAfterSelect, unclearable }: IProps) {
    const [isOpen, setIsOpen] = useState(null);
    const [currentSelected, setCurrentSelected] = useState<{ id: string; title: string } | null>(null);

    useEffect(() => {
        currentSelected && onChange(currentSelected.id);
        if (closeAfterSelect) {
            setIsOpen(null);
        }
    }, [currentSelected]);

    useEffect(() => {
        if (selectedId) {
            const selected = filterItems.find((item) => item.id === selectedId)!;
            setCurrentSelected(selected);
        } else setCurrentSelected(null);
    }, [selectedId]);

    function clearAllFilterState() {
        onChange(null);
        setCurrentSelected(null);
        setIsOpen(null);
    }

    return (
        <div className="filter">
            <WhiteBtn
                variant="contained"
                onClick={(e: any) => setIsOpen(e.currentTarget)}
                endIcon={<ExpandMoreIcon />}
            >
                {title}: {currentSelected?.title}
            </WhiteBtn>
            {isOpen && (
                <Popover
                    open={Boolean(isOpen)}
                    anchorEl={isOpen}
                    onClose={() => setIsOpen(null)}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    PaperProps={{
                        className: 'filter-app-popover',
                    }}
                >
                    {filterItems.map(({ id, title: itemTitle }, idx) => (
                        <Fragment key={id}>
                            <Box
                                onClick={() => {
                                    setCurrentSelected({ id, title: itemTitle });
                                }}
                                className="filter-item-type"
                            >
                                <Box className="filter-item-wrapper">
                                    <Box>{itemTitle}</Box>
                                    {currentSelected?.id === id && <CheckIcon />}
                                </Box>
                            </Box>
                            {idx !== filterItems.length - 1 && <Divider />}
                        </Fragment>
                    ))}
                    {!unclearable && (
                        <>
                            <Divider />
                            <Button
                                sx={{
                                    padding: '14px',
                                }}
                                onClick={() => clearAllFilterState()}
                                fullWidth
                            >
                                Очистить фильтр
                            </Button>
                        </>
                    )}
                </Popover>
            )}
        </div>
    );
}

export default FilterAppType;
