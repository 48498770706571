import MainLayout from 'layouts/MainLayout';

import { Typography, styled } from '@mui/material';
import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { Tabs } from './components/tabs';

export const Accounting = () => {
    const navigate = useNavigate();

    useEffect(() => {
        navigate('deposits');
    }, []);

    return (
        <MainLayout>
            <Container>
                <Typography
                    variant="h4"
                    fontWeight={500}
                >
                    Бухгалтерия
                </Typography>
                <Tabs />
                <Outlet />
            </Container>
        </MainLayout>
    );
};

const Container = styled('div')({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
});
