import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button } from '@mui/material';
import cn from 'classnames';
import React, { useEffect, useState } from 'react';

import IssueStatus from './IssueStatus';

import { EIssueStatus } from '../../enums/issues.enum';
import useOutsideClick from '../../hooks/useOutsideClick';
import './IssuesStatusFilter.scss';

interface IProps {
    selectedIssueStatus: EIssueStatus[] | null;
    onChange: (issuesStatusStr: EIssueStatus[] | null) => void;
}

function IssuesStatusFilter({ selectedIssueStatus, onChange }: IProps) {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const wrapperRef = useOutsideClick(() => {
        setIsOpen(false);
    });

    useEffect(() => {
        return () => {
            onChange(null);
        };
    }, []);

    return (
        <div
            ref={wrapperRef}
            className={cn('issues-status-filter-btn-wrapper', { isOpen })}
        >
            <Button
                variant="contained"
                onClick={() => {
                    setIsOpen(!isOpen);
                }}
                className={selectedIssueStatus?.length ? 'is-active' : 'btn-filter'}
                endIcon={<ExpandMoreIcon />}
            >
                Статус{' '}
                {!!selectedIssueStatus?.length && <span style={{ marginLeft: 10 }}>{selectedIssueStatus?.length}</span>}
            </Button>
            <IssueStatus
                onChange={onChange}
                selectedIssueStatus={selectedIssueStatus}
            />
        </div>
    );
}

export default IssuesStatusFilter;
