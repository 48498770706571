import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import React, { memo, useState } from 'react';

import Filter from './Filter';

import { ESort } from '../../../../enums/tables.enum';
import { IIssue, IIssuesFilters, IIssuesTableHead } from '../../../../interfaces/issues';
import { archiveTableHeadMock, defaultTableHeadMock } from '../../../../mockData/issues';
import { activeFilter } from '../../../../utils/helpers/activeFilter';
import './IssuesTableHead.scss';

interface IProps {
    filters: IIssuesFilters | null;
    setFilters: (value: IIssuesFilters | null) => void;
    isArchive?: boolean;
}

function IssuesTableHead({ filters, setFilters, isArchive }: IProps) {
    const [tableHead, setTableHead] = useState<IIssuesTableHead[]>(
        isArchive ? archiveTableHeadMock : defaultTableHeadMock,
    );

    function sortHandler(propName: keyof IIssue) {
        const newTableHead = tableHead.map((headItem) => {
            if (headItem.property === propName) {
                const newOrder = headItem.order === ESort.Asc ? ESort.Desc : ESort.Asc;
                return { ...headItem, order: newOrder };
            }
            return { ...headItem, order: ESort.Asc };
        });
        setTableHead(newTableHead);

        const foundHead = newTableHead.find(({ property }) => property === propName);
        if (foundHead) {
            setFilters({
                ...(filters || {}),
                order_by: { propName: foundHead.property, order: foundHead.order },
            });
        }
    }

    return (
        <TableHead>
            <TableRow>
                {!!tableHead &&
                    tableHead.map(({ order, property, title, isSorted, padding, isFilter }) => {
                        const filtersWrapperId = `${property}-pop-id`;
                        return (
                            <TableCell
                                key={property}
                                padding={padding}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        position: 'relative',
                                        alignItems: 'center',
                                        width: 'max-content',
                                        fontWeight: activeFilter({ filters, property }).isActive ? '700' : '',
                                    }}
                                >
                                    {!isArchive && isFilter && (
                                        <Filter
                                            filtersWrapperId={filtersWrapperId}
                                            property={property}
                                        />
                                    )}
                                    <TableSortLabel
                                        hideSortIcon={!isSorted}
                                        direction={order}
                                        onClick={() => {
                                            if (!isSorted) return;
                                            sortHandler(property);
                                        }}
                                    >
                                        {title}{' '}
                                        {activeFilter({ filters, property }).count &&
                                            `(${activeFilter({ filters, property }).count})`}
                                    </TableSortLabel>
                                </div>
                            </TableCell>
                        );
                    })}
            </TableRow>
        </TableHead>
    );
}

export default memo(IssuesTableHead);
