import LoadingButton from '@mui/lab/LoadingButton';
import { Button } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { TErrors } from '../../../../../../interfaces';
import { ISpecialization } from '../../../../../../interfaces/specializations';
import {
    editWorkerSpecializationsThunk,
    setErrorsEditWorkerSpecializationsAction,
    setIsOpenEditWorkerSpecializationsAction,
} from '../../../../../../store/actions/edit/editWorkerSpecializations';
import { setWorkerSpecializationsAction } from '../../../../../../store/actions/worker';
import { useAppDispatch } from '../../../../../../store/hooks';
import {
    errorsEditWorkerSpecializationsSelector,
    formValuesEditWorkerSpecializationsSelector,
    workerIdEditWorkerSpecializationsSelector,
} from '../../../../../../store/selectors/edit/editWorkerSpecializationsSelectors';
import defaultErrorCallback from '../../../../../../utils/helpers/defaultErrorCallback';
import { getErrorString } from '../../../../../../utils/helpers/getErrorString';
import './FooterForm.scss';

function FooterForm() {
    const dispatch = useAppDispatch();

    const workerId = useSelector(workerIdEditWorkerSpecializationsSelector);
    const formValues = useSelector(formValuesEditWorkerSpecializationsSelector);
    const errors = useSelector(errorsEditWorkerSpecializationsSelector);

    const [isBtnDisabled, setIsBtnDisabled] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const validator = useCallback(() => {
        const errs = {} as TErrors;

        if (!formValues?.length) {
            errs.sub_options = { message: 'Должна быть выбрана по крайней мере 1 специализация у работника' };
        }

        dispatch(setErrorsEditWorkerSpecializationsAction(errs));
        return !Object.values(errs).some((val) => val);
    }, [formValues]);

    useEffect(() => {
        if (errors) {
            validator();
        }

        setIsBtnDisabled(!formValues);
    }, [formValues]);

    async function submitFormHandler() {
        if (!workerId || !formValues) return;

        if (validator()) {
            try {
                setIsLoading(true);
                const editedSchedule = await dispatch(editWorkerSpecializationsThunk(workerId, formValues));
                dispatch(setWorkerSpecializationsAction(editedSchedule as ISpecialization[]));
                dispatch(setIsOpenEditWorkerSpecializationsAction(false));
            } catch (err) {
                defaultErrorCallback({ errorMessage: getErrorString({ err }) });
            } finally {
                setIsLoading(false);
            }
        }
    }

    return (
        <div className="buttons-wrapper">
            <Button
                variant="contained"
                color="inherit"
                disabled={isBtnDisabled}
                onClick={() => dispatch(setIsOpenEditWorkerSpecializationsAction(false))}
            >
                Отмена
            </Button>

            <LoadingButton
                variant="contained"
                loading={isLoading}
                disabled={isBtnDisabled}
                onClick={submitFormHandler}
            >
                Сохранить
            </LoadingButton>
        </div>
    );
}

export default FooterForm;
