import LoadingButton from '@mui/lab/LoadingButton';
import { Button } from '@mui/material';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import AdditionalPhonesForm from './AdditionalPhonesForm';

import TextField from '../../../../../../components/inputs/TextField';
import { TErrors } from '../../../../../../interfaces';
import { IUpdateClientFormValues } from '../../../../../../interfaces/clients';
import { updateClientThunk } from '../../../../../../store/actions/clients';
import { getIssueThunk } from '../../../../../../store/actions/issues';
import { useAppDispatch } from '../../../../../../store/hooks';
import { issueSelector } from '../../../../../../store/selectors/issuesSelectors';
import defaultErrorCallback from '../../../../../../utils/helpers/defaultErrorCallback';
import { getErrorString } from '../../../../../../utils/helpers/getErrorString';
import showToast from '../../../../../../utils/helpers/showToast';

interface IProps {
    isOpen: boolean;
    onClose: () => void;
}

function EditClientForm({ isOpen, onClose }: IProps) {
    const { issueId } = useParams();

    const dispatch = useAppDispatch();

    const issue = useSelector(issueSelector);
    const { client } = issue || {};

    const [formValues, setFormValues] = useState<IUpdateClientFormValues | null>(null);
    const [errors, setErrors] = useState<TErrors | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!client) return;
        setFormValues({
            first_name: client.first_name,
            last_name: client.last_name,
            phone_number: client.phone_number,
            email: client.email,
            phones: client.phones || [],
        });
        setErrors(null);
    }, [isOpen]);

    useEffect(() => {
        if (errors) {
            validator();
        }
    }, [formValues]);

    const validator = useCallback(() => {
        const errs = {} as TErrors;

        if (!formValues?.first_name) {
            errs.first_name = { message: 'Обязательное поле' };
        }

        if (!formValues?.last_name) {
            errs.last_name = { message: 'Обязательное поле' };
        }

        if (!formValues?.phone_number) {
            errs.phone_number = { message: 'Обязательное поле' };
        }

        setErrors(errs);
        return !Object.values(errs).some((val) => val);
    }, [formValues]);

    async function submitHandler() {
        if (!issue || !formValues || !client) return;

        if (validator()) {
            try {
                if (!issueId) return;
                setIsLoading(true);
                await dispatch(updateClientThunk(client.id, { ...formValues }));
                onClose();
                showToast({
                    isVisible: true,
                    message: 'Изменения сохранены',
                });
                await dispatch(getIssueThunk(issueId));
            } catch (err) {
                defaultErrorCallback({ errorMessage: getErrorString({ err }) });
            } finally {
                setIsLoading(false);
            }
        }
    }

    function changeFirstNameHandler(event: ChangeEvent<HTMLInputElement>) {
        setFormValues({ ...(formValues || {}), first_name: event.target.value });
    }

    function changeLastNameHandler(event: ChangeEvent<HTMLInputElement>) {
        setFormValues({ ...(formValues || {}), last_name: event.target.value });
    }

    function changePhoneNumberHandler(event: ChangeEvent<HTMLInputElement>) {
        setFormValues({ ...(formValues || {}), phone_number: event.target.value });
    }

    function changeEmailHandler(event: ChangeEvent<HTMLInputElement>) {
        setFormValues({ ...(formValues || {}), email: event.target.value });
    }

    return (
        <div className="edit_client_form">
            <TextField
                name="last_name"
                labelText="Фамилия *"
                fullWidth
                params={{ margin: 'normal' }}
                errors={errors}
                value={formValues?.last_name || ''}
                onChange={changeLastNameHandler}
            />
            <TextField
                name="first_name"
                labelText="Имя *"
                fullWidth
                params={{ margin: 'normal' }}
                errors={errors}
                value={formValues?.first_name || ''}
                onChange={changeFirstNameHandler}
            />
            <TextField
                name="phone_number"
                labelText="Основной номер телефона *"
                fullWidth
                params={{ margin: 'normal' }}
                errors={errors}
                value={formValues?.phone_number || ''}
                onChange={changePhoneNumberHandler}
            />
            <AdditionalPhonesForm
                formValues={formValues}
                setFormValues={setFormValues}
            />
            <TextField
                name="email"
                labelText="Электронная почта"
                fullWidth
                params={{ margin: 'normal' }}
                value={formValues?.email || ''}
                onChange={changeEmailHandler}
            />
            <div className="buttons-wrapper">
                <Button
                    variant="contained"
                    color="inherit"
                    onClick={onClose}
                >
                    Отмена
                </Button>

                <LoadingButton
                    variant="contained"
                    loading={isLoading}
                    onClick={submitHandler}
                >
                    Сохранить
                </LoadingButton>
            </div>
        </div>
    );
}

export default EditClientForm;
