import { EIssuesActions } from '../../enums/actions.enum';
import { IIssuesState, TIssuesActions } from '../../interfaces/issues';

const initState: IIssuesState = {
    issue: null,
    issues: null,
    filters: null,
    statusFilters: null,
    tableHead: null,
    pagination: { page: 0, size: 50, total: 0 },
    isIssuesTableLoader: false,
    isOpenCommentDialog: false,
    isOpenDeleteCommentDialog: false,
    selectedIssues: null,
    notesIssue: null,
};

const initialState = { ...initState };

// eslint-disable-next-line default-param-last
export const issuesReducer = (state = initialState, action: TIssuesActions): IIssuesState => {
    switch (action.type) {
        case EIssuesActions.SetIssue:
            return {
                ...state,
                issue: action.payload,
            };
        case EIssuesActions.SetIssues:
            return {
                ...state,
                issues: action.payload,
            };
        case EIssuesActions.SetIssuesFilters:
            return {
                ...state,
                filters: action.payload,
            };
        case EIssuesActions.SetStatusFilters:
            return {
                ...state,
                statusFilters: action.payload,
            };
        case EIssuesActions.SetIssuesTableHead:
            return {
                ...state,
                tableHead: action.payload,
            };
        case EIssuesActions.SetIssuesPagination:
            return {
                ...state,
                pagination: action.payload,
            };
        case EIssuesActions.SetIsTableLoader:
            return {
                ...state,
                isIssuesTableLoader: action.payload,
            };
        case EIssuesActions.SetIsOpenCommentDialog:
            return {
                ...state,
                isOpenCommentDialog: action.payload,
            };
        case EIssuesActions.SetIsOpenDeleteCommentDialog:
            return {
                ...state,
                isOpenDeleteCommentDialog: action.payload,
            };
        case EIssuesActions.SetSelectedIssues:
            return {
                ...state,
                selectedIssues: action.payload,
            };
        case EIssuesActions.SetNotesIssue:
            return {
                ...state,
                notesIssue: action.payload,
            };
        default:
            return state;
    }
};
