import { Checkbox, Chip, TableCell, TableRow } from '@mui/material';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import Observe from './Observe';
import Reminders from './Reminders';

import { EIssueStatus } from '../../../../enums/issues.enum';
import { ERoutes } from '../../../../enums/routes.enum';
import { IIssue } from '../../../../interfaces/issues';
import { issueCreatedSourceMock, issueHelpTypeNamesMock, issueStatusesMock } from '../../../../mockData/issues';
import { setSelectedIssuesAction } from '../../../../store/actions/issues';
import { useAppDispatch } from '../../../../store/hooks';
import { selectedIssuesSelector } from '../../../../store/selectors/issuesSelectors';
import { dateFormatter } from '../../../../utils/helpers/dateFormatter';
import { getCategoriesNameIssue } from '../../../../utils/helpers/getCategoriesNameIssue';
import { getFullName } from '../../../../utils/helpers/getFullName';
import { timeWindowsFormatter } from '../../../../utils/helpers/timeWindowsFormatter';

function IssueRow(issue: IIssue) {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const selectedIssues = useSelector(selectedIssuesSelector);

    const isDisabled = useMemo(() => {
        if (issue?.status === EIssueStatus.Processed) {
            if (issue?.address_parts) {
                if (!!issue?.address_parts?.lat && !!issue?.address_parts?.long) {
                    return false;
                }
            }
        }
        return true;
    }, [issue]);

    const {
        id,
        oid,
        client,
        help_type,
        status,
        created_at,
        worker,
        created_source,
        region,
        count_unseen_messages,
        time_windows,
        partner,
        reminders,
        sub_issues,
        observers,
    } = issue;

    const onCheckedHandler = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
    };
    const onChangeCheckHandler = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        checked
            ? dispatch(setSelectedIssuesAction(selectedIssues ? [...selectedIssues, id] : [id]))
            : dispatch(
                  setSelectedIssuesAction(selectedIssues ? selectedIssues.filter((issueId) => issueId !== id) : null),
              );
    };

    const goToIssue = () => {
        navigate(`/${ERoutes.Issues}/${id}`);
    };

    return (
        <TableRow
            onClick={goToIssue}
            sx={{ cursor: 'pointer' }}
        >
            <TableCell>
                <Checkbox
                    disabled={isDisabled}
                    onClick={onCheckedHandler}
                    onChange={onChangeCheckHandler}
                    checked={!!selectedIssues && selectedIssues.includes(id)}
                />
            </TableCell>
            <TableCell>
                <Link
                    to={`/${ERoutes.Issues}/${id}`}
                    onClick={(e) => e.stopPropagation()}
                    style={{ color: 'inherit', textDecoration: 'underline', cursor: 'pointer' }}
                >
                    {oid}
                </Link>
            </TableCell>
            <TableCell>
                {!!client && !client.is_removed ? (
                    getFullName(client)
                ) : (
                    <span style={{ color: '#8A92A6' }}>Пользователь удален</span>
                )}
            </TableCell>
            <TableCell>{getCategoriesNameIssue(sub_issues)}</TableCell>
            <TableCell>{issueHelpTypeNamesMock[help_type]}</TableCell>
            <TableCell padding="none">
                <Chip
                    label={issueStatusesMock[status]?.title}
                    sx={{
                        fontWeight: '600',
                        backgroundColor: issueStatusesMock[status]?.colorHex,
                        color: issueStatusesMock[status]?.colorText,
                        '&:hover': { backgroundColor: `${issueStatusesMock[status]?.colorHex}`.slice(0, -2) + 99 },
                    }}
                    size={'small'}
                    clickable
                />
            </TableCell>
            <TableCell>
                {dateFormatter(
                    created_at,
                    {},
                    { day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' },
                )}
            </TableCell>
            <TableCell sx={{ width: '130px' }}>{timeWindowsFormatter(time_windows)}</TableCell>
            <TableCell>
                {worker ? getFullName(worker) : <span style={{ color: '#8A92A6' }}>Мастер не назначен</span>}
            </TableCell>
            <TableCell>
                {created_source && (
                    <>
                        {issueCreatedSourceMock[created_source]} {partner && <span>/</span>} <div>{partner?.name}</div>
                    </>
                )}
            </TableCell>
            <TableCell>{region}</TableCell>
            <TableCell>
                {!!count_unseen_messages && (
                    <Chip
                        label={count_unseen_messages}
                        color={'error'}
                        size={'small'}
                    />
                )}
            </TableCell>
            <TableCell align="center">{!!reminders?.length && <Reminders reminders={reminders} />}</TableCell>
            <TableCell align="center">{!!observers?.length && <Observe observers={observers} />}</TableCell>
        </TableRow>
    );
}

export default IssueRow;
