import { Button } from 'components/Button';
import { issueHelpTypeNamesMock } from 'mockData/issues';
import { setDepositId, setDepositIds } from 'pages/accounting/deposits/store';
import { depositsSelector } from 'pages/accounting/deposits/store/selectors';
import { TDeposit } from 'pages/accounting/deposits/store/types';
import { useAppDispatch } from 'store/hooks';
import { dateFormatter } from 'utils/helpers/dateFormatter';

import { Checkbox, FormControlLabel, TableBody, TableCell, TableRow, styled } from '@mui/material';
import { FC, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { useSelector } from 'react-redux';

import { AccrualModal } from '../modals/accrual';

type Props = {
    rows: TDeposit[];
};

export const Body: FC<Props> = ({ rows }) => {
    const dispatch = useAppDispatch();

    const [open, setOpen] = useState(false);

    const { depositIds } = useSelector(depositsSelector);

    const handleChange = (id: string, checked: boolean) => {
        if (!checked) {
            dispatch(setDepositIds(depositIds.filter((itemId) => itemId !== id)));
        } else {
            dispatch(setDepositIds([...depositIds, id]));
        }
    };

    const dateFormat: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'numeric', year: 'numeric' };

    return (
        <>
            <TableBody>
                {rows.map((row) => {
                    return (
                        <TableRow key={row.id}>
                            <TableCell>
                                <Label
                                    checked={depositIds.includes(row.id) || false}
                                    control={<Checkbox />}
                                    label={row.issue.oid}
                                    onChange={(_, checked) => {
                                        handleChange(row.id, checked);
                                    }}
                                />
                            </TableCell>
                            <Cell>-</Cell>
                            <Cell>{row.worker ? `${row.worker?.last_name} ${row.worker?.first_name}` : '-'}</Cell>
                            <Cell>{issueHelpTypeNamesMock[row.issue.help_type]}</Cell>
                            <Cell>{dateFormatter(row.issue.created_at, {}, dateFormat)}</Cell>
                            <Cell>{dateFormatter(row.created_at, {}, dateFormat)}</Cell>
                            <Cell>{row.payment_method === 'without_cash' ? 'Безнал' : 'Нал'}</Cell>
                            <Cell>
                                <NumericFormat
                                    displayType="text"
                                    value={row.total_amount}
                                    suffix=" ₽"
                                    thousandSeparator=" "
                                />
                            </Cell>
                            <Cell>
                                <NumericFormat
                                    allowNegative={false}
                                    displayType="text"
                                    value={row.to_deposit_amount}
                                    prefix={row.to_deposit_amount < 0 ? '- ' : ''}
                                    suffix=" ₽"
                                    thousandSeparator=" "
                                />
                            </Cell>
                            <TableCell align="right">
                                <Button
                                    onClick={() => {
                                        dispatch(setDepositId(row.id));
                                        setOpen(true);
                                    }}
                                    variant="contained"
                                >
                                    Начислить
                                </Button>
                            </TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
            <AccrualModal
                open={open}
                onClose={() => setOpen(false)}
            />
        </>
    );
};

const Cell = styled(TableCell)({
    fontWeight: '400',
    fontSize: '14px',
});

const Label = styled(FormControlLabel)({
    margin: '0',
    '.MuiTypography-root': {
        fontWeight: '400',
        fontSize: '14px',
    },
});
