import LoadingButton from '@mui/lab/LoadingButton';
import { Button } from '@mui/material';
import { isToday } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { TErrors } from '../../../../../interfaces';
import {
    setErrorsEditIssueReminderAction,
    setIsOpenEditIssueReminderAction,
} from '../../../../../store/actions/edit/editIssueReminder';
import { editIssueReminderThunk, getIssueRemindersThunk } from '../../../../../store/actions/reminders';
import { useAppDispatch } from '../../../../../store/hooks';
import {
    errorsEditIssueReminderSelector,
    formValuesEditIssueReminderSelector,
} from '../../../../../store/selectors/edit/editIssueReminderSelectors';
import { reminderSelector } from '../../../../../store/selectors/remindersSelector';
import defaultErrorCallback from '../../../../../utils/helpers/defaultErrorCallback';
import { getErrorString } from '../../../../../utils/helpers/getErrorString';
import { isDateFuture } from '../../../../../utils/helpers/isDateFuture';
import { isTimeFuture } from '../../../../../utils/helpers/isTimeFuture';

import './FooterForm.scss';

function FooterForm() {
    const { issueId } = useParams();
    const dispatch = useAppDispatch();

    const reminder = useSelector(reminderSelector);
    const formValues = useSelector(formValuesEditIssueReminderSelector);
    const errors = useSelector(errorsEditIssueReminderSelector);

    const [isBtnDisabled, setIsBtnDisabled] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const validator = useCallback(() => {
        const errs = {} as TErrors;

        if (!formValues?.text) {
            errs.text = { message: 'Укажите текст напоминания' };
        }

        if (!formValues?.date_delivery) {
            errs.date_delivery = { message: 'Выберите дату' };
        } else if (!isDateFuture(formValues.date_delivery)) {
            errs.date_delivery = { message: 'Не корректное значение' };
        }

        if (!formValues?.time_delivery) {
            errs.time_delivery = { message: 'Выберите время' };
        } else if (
            formValues.date_delivery &&
            isToday(formValues.date_delivery) &&
            !isTimeFuture(formValues.time_delivery)
        ) {
            errs.time_delivery = { message: 'Не корректное значение' };
        }

        dispatch(setErrorsEditIssueReminderAction(errs));
        return !Object.values(errs).some((val) => val);
    }, [formValues]);

    useEffect(() => {
        if (errors) {
            validator();
        }

        setIsBtnDisabled(!formValues);
    }, [formValues]);

    async function submitFormHandler() {
        if (!reminder?.id || !formValues || !issueId) return;

        if (validator()) {
            try {
                setIsLoading(true);
                await dispatch(editIssueReminderThunk(reminder?.id, formValues));
                dispatch(setIsOpenEditIssueReminderAction(false));
                await dispatch(getIssueRemindersThunk(issueId));
            } catch (err) {
                defaultErrorCallback({ errorMessage: getErrorString({ err }) });
            } finally {
                setIsLoading(false);
            }
        }
    }

    return (
        <div className="buttons-wrapper">
            <Button
                variant="contained"
                color="inherit"
                disabled={isBtnDisabled}
                onClick={() => dispatch(setIsOpenEditIssueReminderAction(false))}
            >
                Отмена
            </Button>

            <LoadingButton
                variant="contained"
                loading={isLoading}
                disabled={isBtnDisabled}
                onClick={submitFormHandler}
            >
                Сохранить
            </LoadingButton>
        </div>
    );
}

export default FooterForm;
