import AccessTimeFilledRoundedIcon from '@mui/icons-material/AccessTimeFilledRounded';
import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp';
import ErrorSharpIcon from '@mui/icons-material/ErrorSharp';
import { teal } from '@mui/material/colors';

import { TIssueMasterSearchStatus } from './issues';

import { EAutoAssignmentStatus } from '../enums/issues.enum';
import theme from '../theme';

export const IssueMasterSearchStatus: TIssueMasterSearchStatus = {
    [EAutoAssignmentStatus.Started]: {
        title: 'Ожидаем, пока первый свободный мастер примет заявку...',
        color: teal['400'],
        icon: <AccessTimeFilledRoundedIcon sx={{ color: teal['400'] }} />,
    },
    [EAutoAssignmentStatus.Pending]: {
        title: 'Ожидаем начала поиска',
        color: teal['400'],
        icon: <AccessTimeFilledRoundedIcon sx={{ color: teal['400'] }} />,
    },
    [EAutoAssignmentStatus.Finished]: {
        title: 'Назначен',
        color: theme.palette.success.light,
        icon: <CheckCircleSharpIcon sx={{ color: theme.palette.success.light }} />,
    },
    [EAutoAssignmentStatus.Error]: {
        title: 'Нет подходящих мастеров',
        color: theme.palette.error.light,
        icon: <ErrorSharpIcon sx={{ color: theme.palette.error.light }} />,
    },
};
