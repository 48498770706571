/* eslint-disable no-shadow */

export enum ERoutes {
    Home = '/',
    SignIn = 'signin',
    Issues = 'issues',
    ExternalForm = 'external-form',
    Workers = 'workers',
    Prices = 'prices',
    PriceServices = 'prices/service',
    Price = 'prices',
    Map = 'map',
    Chats = 'chats',
    ImportIssues = 'import-issues',
    //todo выяснить - доделать
    Test1 = 'test1',
    Archive = 'archive',
    Automation = 'automation',
    Partner = 'partner',
    Support = 'support',
    Accounting = 'accounting',
    Deposits = 'deposits',
    Withdraws = 'withdraws',
    Transactions = 'transactions',
}
