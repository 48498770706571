import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { cancelDialogAction } from '../../../store/actions/dialog';
import { deleteGroupThunk, getGroupsThunk, setGroupAction } from '../../../store/actions/groups';
import { getWorkersThunk } from '../../../store/actions/workers';
import { useAppDispatch } from '../../../store/hooks';
import { groupSelector } from '../../../store/selectors/groupsSelector';
import { workersFiltersSelector } from '../../../store/selectors/workersSelectors';
import defaultErrorCallback from '../../../utils/helpers/defaultErrorCallback';
import { getErrorString } from '../../../utils/helpers/getErrorString';
import './DeleteGroup.scss';

function DeleteGroup() {
    const dispatch = useAppDispatch();

    const group = useSelector(groupSelector);
    const filters = useSelector(workersFiltersSelector);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    async function deleteGroup() {
        try {
            if (!group) return;
            setIsLoading(true);
            await dispatch(deleteGroupThunk(group.id));
            await dispatch(getGroupsThunk());
            await dispatch(getWorkersThunk({ filters }));
            await dispatch(getGroupsThunk());
            dispatch(setGroupAction(null));
        } catch (err) {
            defaultErrorCallback({ errorMessage: getErrorString({ err }) });
        } finally {
            closeHandler();
            setIsLoading(false);
        }
    }

    const closeHandler = () => {
        dispatch(cancelDialogAction());
    };

    return (
        <div className="delete-group-form-wrapper">
            <div className="delete-group-form-title">
                <DeleteOutlineIcon
                    color="error"
                    className="title-icon"
                    fontSize="large"
                />{' '}
                <div className="title-text">Удалить группу</div>
            </div>
            <div className="delete-group-form-description">
                <p>
                    Вы действительно хотите удалить группу <span>{group?.name}</span>?
                </p>
                <p>Все участники этой группы будут расформированы</p>
                <div className="buttons">
                    <Button
                        onClick={closeHandler}
                        color="primary"
                        variant="outlined"
                    >
                        Отмена
                    </Button>
                    <LoadingButton
                        loading={isLoading}
                        variant="contained"
                        color="error"
                        sx={{ ml: 1 }}
                        onClick={deleteGroup}
                    >
                        Удалить
                    </LoadingButton>
                </div>
            </div>
        </div>
    );
}

export default DeleteGroup;
