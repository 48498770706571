import LoadingButton from '@mui/lab/LoadingButton';
import { Button } from '@mui/material';
import { TErrors } from 'interfaces';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
    setErrorsEditIssueStatusWithCommentAction,
    setIsOpenEditIssueStatusWithCommentAction,
} from '../../../../../../../store/actions/edit/editIssueStatusWithComment';
import { putIssueStatusThunk } from '../../../../../../../store/actions/issues';
import { useAppDispatch } from '../../../../../../../store/hooks';
import {
    dataEditIssueStatusWithCommentSelector,
    errorsEditIssueStatusWithCommentSelector,
    formValuesEditIssueStatusWithCommentSelector,
    issueIdEditIssueStatusWithCommentSelector,
} from '../../../../../../../store/selectors/edit/editIssueStatusWithCommentSelectors';
import defaultErrorCallback from '../../../../../../../utils/helpers/defaultErrorCallback';
import { getErrorString } from '../../../../../../../utils/helpers/getErrorString';
import './FooterForm.scss';

function FooterForm() {
    const dispatch = useAppDispatch();

    const issueId = useSelector(issueIdEditIssueStatusWithCommentSelector);
    const formValues = useSelector(formValuesEditIssueStatusWithCommentSelector);
    const errors = useSelector(errorsEditIssueStatusWithCommentSelector);
    const data = useSelector(dataEditIssueStatusWithCommentSelector);

    const [isBtnDisabled, setIsBtnDisabled] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const validator = useCallback(() => {
        const errs = {} as TErrors;
        dispatch(setErrorsEditIssueStatusWithCommentAction(errs));
        return !Object.values(errs).some((val) => val);
    }, [formValues]);

    useEffect(() => {
        if (errors) {
            validator();
        }
        if (data?.newStatus === 'processed' || data?.newStatus === 'waiting_call') {
            setIsBtnDisabled(!formValues || formValues.comment?.length === 0);
        } else {
            setIsBtnDisabled(false);
        }
    }, [formValues]);

    async function submitFormHandler() {
        if (!issueId || !data) return;

        if (validator()) {
            try {
                setIsLoading(true);
                await dispatch(
                    putIssueStatusThunk({
                        issueId,
                        newStatus: data.newStatus,
                        putCancelIssueStatusData: data.putCancelIssueStatusData,
                        putSolvedIssueStatusData: data.putSolvedIssueStatusData,
                        comment: formValues?.comment,
                    }),
                );
                dispatch(setIsOpenEditIssueStatusWithCommentAction(false));
            } catch (err) {
                defaultErrorCallback({ errorMessage: getErrorString({ err }) });
            } finally {
                setIsLoading(false);
            }
        }
    }

    return (
        <div className="buttons-wrapper">
            <Button
                variant="contained"
                color="inherit"
                onClick={() => dispatch(setIsOpenEditIssueStatusWithCommentAction(false))}
            >
                Отмена
            </Button>

            <LoadingButton
                variant="contained"
                loading={isLoading}
                disabled={isBtnDisabled}
                onClick={submitFormHandler}
            >
                Сохранить
            </LoadingButton>
        </div>
    );
}

export default FooterForm;
