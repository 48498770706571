import { blueGrey, common } from '@mui/material/colors';
import { ruRU as coreRU } from '@mui/material/locale';
import { createTheme } from '@mui/material/styles';
import { ruRU as dataGridRU } from '@mui/x-data-grid';
import { ruRU } from '@mui/x-date-pickers';

const scrollBarStyles = {
    '&::-webkit-scrollbar-track': {
        position: 'absolute',
        right: 10,
        borderRadius: 10,
        backgroundColor: '#F5F5F5',
        margin: '5px 0 0 0',
    },
    '&::-webkit-scrollbar': {
        width: 4,
        height: 4,
    },
    '&::-webkit-scrollbar-thumb': {
        borderRadius: 10,
        backgroundColor: '#949494',
    },
    '::-webkit-scrollbar:horizontal': {
        padding: 0,
    },
    '::-webkit-scrollbar:vertical': {
        padding: 0,
    },
};

const theme = createTheme(
    {
        components: {
            MuiCssBaseline: {
                styleOverrides: {
                    div: scrollBarStyles,
                },
            },
            MuiInputBase: {
                styleOverrides: {
                    root: {
                        background: common.white,
                        border: blueGrey[100],
                    },
                },
            },
            MuiAutocomplete: {
                styleOverrides: {
                    listbox: {
                        '& .MuiAutocomplete-option[aria-selected="true"]': {
                            backgroundColor: '#fff',
                        },
                    },
                },
            },
        },
        typography: {
            subtitle1: {
                fontSize: 12,
            },
        },
    },
    ruRU,
    dataGridRU,
    coreRU,
);
theme.typography.h5 = { ...theme.typography.h5, fontWeight: 600 };

export default theme;
