import LoadingButton from '@mui/lab/LoadingButton';
import { Button } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { TErrors } from '../../../../../../interfaces';
import {
    editWorkerScheduleThunk,
    setErrorsEditWorkerScheduleAction,
    setIsOpenEditWorkerScheduleAction,
} from '../../../../../../store/actions/edit/editWorkerSchedule';
import { setWorkerScheduleAction } from '../../../../../../store/actions/worker';
import { useAppDispatch } from '../../../../../../store/hooks';
import {
    errorsEditWorkerScheduleSelector,
    formValuesEditWorkerScheduleSelector,
    workerIdEditWorkerScheduleSelector,
} from '../../../../../../store/selectors/edit/editWorkerScheduleSelectors';
import defaultErrorCallback from '../../../../../../utils/helpers/defaultErrorCallback';
import { getErrorString } from '../../../../../../utils/helpers/getErrorString';
import './FooterForm.scss';

function FooterForm() {
    const dispatch = useAppDispatch();

    const workerId = useSelector(workerIdEditWorkerScheduleSelector);
    const formValues = useSelector(formValuesEditWorkerScheduleSelector);
    const errors = useSelector(errorsEditWorkerScheduleSelector);

    const [isBtnDisabled, setIsBtnDisabled] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const validator = useCallback(() => {
        const errs = {} as TErrors;

        formValues?.forEach((day, index) => {
            day.time_windows.forEach((timeWindow, windowIndex) => {
                if (timeWindow.start_time && timeWindow.end_time) {
                    if (!timeWindow.start_time) {
                        errs[`start_time_${index}_${windowIndex}`] = { message: 'Обязательное поле' };
                    }

                    if (!timeWindow.end_time) {
                        errs[`end_time_${index}_${windowIndex}`] = { message: 'Обязательное поле' };
                    }

                    if (timeWindow.start_time === timeWindow.end_time) {
                        errs[`start_time_${index}_${windowIndex}`] = { message: 'Время не может быть одинаковым' };
                        errs[`end_time_${index}_${windowIndex}`] = { message: 'Время не может быть одинаковым' };
                    }

                    for (let i = 0; i < windowIndex; i += 1) {
                        const otherTimeWindow = day.time_windows[i];
                        const startTime = new Date(`2000-01-01T${timeWindow.start_time}`);
                        const endTime = new Date(`2000-01-01T${timeWindow.end_time}`);
                        const otherStartTime = new Date(`2000-01-01T${otherTimeWindow.start_time}`);
                        const otherEndTime = new Date(`2000-01-01T${otherTimeWindow.end_time}`);

                        if (
                            (startTime >= otherStartTime && startTime <= otherEndTime) ||
                            (endTime >= otherStartTime && endTime <= otherEndTime) ||
                            (otherStartTime >= startTime && otherStartTime <= endTime) ||
                            (otherEndTime >= startTime && otherEndTime <= endTime)
                        ) {
                            errs[`start_time_${index}_${windowIndex}`] = { message: 'Наслоение времени' };
                            errs[`end_time_${index}_${windowIndex}`] = { message: 'Наслоение времени' };
                            break;
                        }
                    }
                }
            });
        });
        dispatch(setErrorsEditWorkerScheduleAction(errs));
        return !Object.values(errs).some((val) => val);
    }, [formValues]);

    useEffect(() => {
        if (errors) {
            validator();
        }

        setIsBtnDisabled(!formValues);
    }, [formValues]);

    async function submitFormHandler() {
        if (!workerId || !formValues) return;

        if (validator()) {
            try {
                setIsLoading(true);
                const editedSchedule = await dispatch(editWorkerScheduleThunk(workerId, formValues));
                dispatch(setWorkerScheduleAction(editedSchedule));
                dispatch(setIsOpenEditWorkerScheduleAction(false));
            } catch (err) {
                defaultErrorCallback({ errorMessage: getErrorString({ err }) });
            } finally {
                setIsLoading(false);
            }
        }
    }

    return (
        <div className="buttons-wrapper">
            <LoadingButton
                variant="contained"
                loading={isLoading}
                disabled={isBtnDisabled}
                onClick={submitFormHandler}
            >
                Сохранить
            </LoadingButton>
            <Button
                variant="contained"
                color="inherit"
                disabled={isBtnDisabled}
                onClick={() => dispatch(setIsOpenEditWorkerScheduleAction(false))}
            >
                Отмена
            </Button>
        </div>
    );
}

export default FooterForm;
