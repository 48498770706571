import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button } from '@mui/material';
import cn from 'classnames';
import React, { useEffect, useState } from 'react';

import HelpType from './HelpType';

import { EIssueHelpType } from '../../enums/issues.enum';
import useOutsideClick from '../../hooks/useOutsideClick';
import './HelpTypeFilter.scss';

interface IProps {
    selectedHelpType: EIssueHelpType[] | null;
    onChange: (helpTypeStr: EIssueHelpType[] | null) => void;
}

function HelpTypeFilter({ selectedHelpType, onChange }: IProps) {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const wrapperRef = useOutsideClick(() => {
        setIsOpen(false);
    });

    useEffect(() => {
        return () => {
            onChange(null);
        };
    }, []);

    return (
        <div
            ref={wrapperRef}
            className={cn('help-type-filter-btn-wrapper', { isOpen })}
        >
            <Button
                variant="contained"
                onClick={() => {
                    setIsOpen(!isOpen);
                }}
                className={selectedHelpType?.length ? 'is-active' : 'btn-filter'}
                endIcon={<ExpandMoreIcon />}
            >
                Ремонт/Услуга{' '}
                {!!selectedHelpType?.length && <span style={{ marginLeft: 10 }}>{selectedHelpType?.length}</span>}
            </Button>
            <HelpType
                onChange={onChange}
                selectedHelpType={selectedHelpType}
            />
        </div>
    );
}

export default HelpTypeFilter;
