import FilterAppType from 'components/FilterAppType';
import { setChatGroupByUserFiltersAction } from 'store/actions/chatGroupByUser';
import { setReloadAction } from 'store/actions/chatGroupByUsers';
import { getChatsInfiniteThunk } from 'store/actions/chatsInfinite';
import { useAppDispatch } from 'store/hooks';
import { chatGroupByUserFiltersSelector, chatGroupByUserSelector } from 'store/selectors/chatGroupByUserSelectors';

import { Add, History } from '@mui/icons-material';
import { Button, Radio, styled } from '@mui/material';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const ControlsWrapper = styled('div')({
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    margin: '10px 0',
});

const Btn = styled(Button)({
    textTransform: 'none',
});

const WhiteBtn = styled(Button)({
    background: '#fff',
    textTransform: 'none',
    color: '#000',
    ':hover': {
        background: '#ffffff90',
    },
});

type Props = {
    onAddChatClick: () => void;
    onIssueHistoryClick: () => void;
    isIssueHistoryOpen: boolean;
};

const chatsFilters = [
    { id: 'support', title: 'Поддержка' },
    { id: 'issue_support', title: 'Заявка' },
    { id: 'issue_related', title: 'Клиент-мастер' },
];

export function ChatsControls({ onIssueHistoryClick, isIssueHistoryOpen, onAddChatClick }: Props) {
    const dispatch = useAppDispatch();

    const user = useSelector(chatGroupByUserSelector);
    const filters = useSelector(chatGroupByUserFiltersSelector);

    const filterByChatType = (chat_type: string | null) => {
        dispatch(
            getChatsInfiniteThunk({
                filters: {
                    user_id: user?.user_id,
                    user_type: user?.user_type,
                    chat_type,
                    has_unread_message: filters.has_unread_message || null,
                },
                page: 1,
                size: 30,
                sortOptions: new Map().set('last_message_at', 'desc').set('created_at', 'desc'),
            }),
        );
    };

    const filterChatsByUnreadMessage = (has_unread_message: boolean) => {
        dispatch(
            getChatsInfiniteThunk({
                filters: {
                    user_id: user?.user_id,
                    user_type: user?.user_type,
                    chat_type: filters.chat_type,
                    has_unread_message: has_unread_message || null,
                },
                page: 1,
                size: 30,
                sortOptions: new Map().set('last_message_at', 'desc').set('created_at', 'desc'),
            }),
        );
    };

    const resetFilters = () => {
        dispatch(setChatGroupByUserFiltersAction({ has_unread_message: false, chat_type: null }));
    };

    const reloadCallback = () => {
        resetFilters();
    };

    useEffect(() => {
        dispatch(setReloadAction({ reloadCallback }));
    }, []);

    return (
        <ControlsWrapper>
            <FilterAppType
                selectedId={filters.chat_type || undefined}
                title="Тип чата"
                onChange={filterByChatType}
                filterItems={chatsFilters}
                closeAfterSelect
            />
            <WhiteBtn
                onClick={() => {
                    filterChatsByUnreadMessage(!filters.has_unread_message);
                }}
                startIcon={
                    <Radio
                        inputProps={{ type: 'checkbox' }}
                        checked={filters.has_unread_message ?? false}
                        sx={{ padding: 0 }}
                    />
                }
                variant="contained"
            >
                Непрочитанные
            </WhiteBtn>
            <Btn
                onClick={onIssueHistoryClick}
                variant="contained"
                color="primary"
                sx={{ marginLeft: 'auto' }}
                startIcon={<History />}
            >
                {isIssueHistoryOpen ? 'Скрыть' : 'Показать'} историю заявок
            </Btn>
            <Btn
                onClick={onAddChatClick}
                variant="contained"
                color="primary"
                startIcon={<Add />}
            >
                Создать чат
            </Btn>
        </ControlsWrapper>
    );
}
