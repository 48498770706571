import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { EPricesServicesTabs } from '../../../../enums/pricesServices.enum';
import { ERoutes } from '../../../../enums/routes.enum';
import { deletePriceThunk } from '../../../../store/actions/priceDialog';
import { getPricesThunk, setIsOpenDeletePriceAction } from '../../../../store/actions/prices';
import { useAppDispatch } from '../../../../store/hooks';
import { priceSelector, pricesSelector } from '../../../../store/selectors/pricesSelectors';
import { priceServiceSelector, priceServicesTabSelector } from '../../../../store/selectors/pricesServicesSelector';
import defaultErrorCallback from '../../../../utils/helpers/defaultErrorCallback';
import { getErrorString } from '../../../../utils/helpers/getErrorString';
import showToast from '../../../../utils/helpers/showToast';

function FooterForm() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const prices = useSelector(pricesSelector);
    const price = useSelector(priceSelector);
    const priceService = useSelector(priceServiceSelector);
    const serviceTabs = useSelector(priceServicesTabSelector);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { id: priceId } = price || {};

    async function submitFormHandler() {
        try {
            if (!priceId) return;
            setIsLoading(true);
            await dispatch(deletePriceThunk(priceId));
            await dispatch(setIsOpenDeletePriceAction(false));
            await (!prices
                ? navigate(`/${ERoutes.Prices}`)
                : dispatch(
                      getPricesThunk({
                          filters: {
                              is_additional: serviceTabs !== EPricesServicesTabs.BasicServices,
                              service_id__in: priceService?.id,
                          },
                      }),
                  ));
            showToast({
                isVisible: true,
                message: 'Услуга удалена',
            });
        } catch (err) {
            defaultErrorCallback({ errorMessage: getErrorString({ err }) });
        } finally {
            setIsLoading(false);
        }
    }
    return (
        <Box mt={2}>
            <Button
                onClick={() => dispatch(setIsOpenDeletePriceAction(false))}
                color="primary"
                variant="outlined"
            >
                Отмена
            </Button>

            <LoadingButton
                onClick={submitFormHandler}
                loading={isLoading}
                color="error"
                variant="contained"
                sx={{ ml: 1 }}
            >
                Удалить
            </LoadingButton>
        </Box>
    );
}

export default FooterForm;
