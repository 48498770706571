/* eslint-disable no-shadow */
export const enum EWebsocketsActions {
    SetWSHistory = 'Set_WS_History',
    SetWSChannel = 'Set_WS_CHANNEL',
}

export const enum ESignInActions {
    SetType = 'SET_TYPE_SIGN_IN',
    SetEmail = 'SET_EMAIL_SIGN_IN',
}

export const enum EProfileActions {
    SetProfile = 'SET_PROFILE',
    SetIsAuth = 'SET_IS_AUTH_PROFILE',
    Logout = 'LOGOUT',
}

export const enum ERolesActions {
    SetRoles = 'SET_ROLES',
}

export const enum ECategoriesActions {
    SetCategory = 'SET_CATEGORY',
    SetCategories = 'SET_CATEGORIES',
    SetSearchCategories = 'SET_SEARCH_CATEGORIES',
    SetCategoriesPagination = 'SET_CATEGORIES_PAGINATION',
}

export const enum EBrandsActions {
    SetBrand = 'SET_BRAND',
    SetBrands = 'SET_BRANDS',
}

export const enum EIssuesActions {
    SetIssues = 'SET_ISSUES',
    SetIssue = 'SET_ISSUE',
    SetIssuesFilters = 'SET_ISSUE_FILTERS',
    SetStatusFilters = 'SET_STATUS_FILTERS',
    SetIssuesTableHead = 'SET_ISSUES_TABLE_HEAD',
    SetIssuesPagination = 'SET_ISSUE_PAGINATION',
    SetIsTableLoader = 'SET_IS_TABLE_LOADER_ISSUES',
    SetIsOpenCommentDialog = 'SET_IS_OPEN_COMMENT_DIALOG',
    SetIsOpenDeleteCommentDialog = 'SET_IS_OPEN_DELETE_COMMENT_DIALOG',
    SetSelectedIssues = 'SET_SELECTED_ISSUES',
    SetNotesIssue = 'SET_NOTES_ISSUE',
}

export const enum EClientsActions {
    SetClients = 'SET_CLIENTS',
    SetClient = 'SET_CLIENT',
    SetFormValues = 'SET_FORM_VALUES_CREATE_CLIENT',
    SetErrors = 'SET_ERRORS_CREATE_CLIENT',
    SetIsOpenCreateClient = 'SET_IS_OPEN_CREATE_CLIENT',
    SetPagination = 'SET_PAGINATION_CLIENTS',
}

export const enum EDevicesActions {
    SetDevice = 'SET_DEVICE',
    SetHomeDevice = 'SET_HOME_DEVICE',
    SetHomeDevices = 'SET_HOME_DEVICES',
    SetIsOpen = 'SET_IS_OPEN_CREATE_DEVICE',
}

export const enum ECreateIssueActions {
    SetFormValues = 'SET_FORM_VALUES_CREATE_ISSUE',
    SetSelectedPrices = 'SET_SELECTED_PRICES_CREATE_ISSUE',
    SetErrors = 'SET_ERRORS_CREATE_ISSUE',
    SetIsOpen = 'SET_IS_OPEN_CREATE_ISSUE',
}

export const enum EEditIssueStatusToCancelActions {
    SetIsOpen = 'SET_IS_OPEN_EDIT_ISSUE_STATUS_TO_CANCEL',
    SetIssueId = 'SET_IS_ISSUE_ID_EDIT_ISSUE_STATUS_TO_CANCEL',
    SetFormValues = 'SET_FORM_VALUES_EDIT_ISSUE_STATUS_TO_CANCEL',
    SetErrors = 'SET_ERRORS_EDIT_ISSUE_STATUS_TO_CANCEL',
    SetCancelationReasons = 'SET_CANCELATIONREASONS_EDIT_ISSUE_STATUS_TO_CANCEL',
}

export const enum EEditIssueStatusWithCommentActions {
    SetIsOpen = 'SET_IS_OPEN_EDIT_ISSUE_STATUS_WITH_COMMENT',
    SetIssueId = 'SET_IS_ISSUE_ID_EDIT_ISSUE_STATUS_WITH_COMMENT',
    SetFormValues = 'SET_FORM_VALUES_EDIT_ISSUE_STATUS_WITH_COMMENT',
    SetErrors = 'SET_ERRORS_EDIT_ISSUE_STATUS_WITH_COMMENT',
    SetData = 'SET_DATA_EDIT_ISSUE_STATUS_WITH_COMMENT',
}

export const enum EEditIssueStatusToMasterSearchActions {
    SetIsOpen = 'SET_IS_OPEN_EDIT_ISSUE_STATUS_TO_MASTER_SEARCH',
}

export const enum ECreateIssueReminderActions {
    SetIsOpen = 'SET_IS_OPEN_CREATE_ISSUE_REMINDER',
    SetIssueId = 'SET_ISSUE_ID_CREATE_ISSUE_REMINDER',
    SetFormValues = 'SET_FORM_VALUES_CREATE_ISSUE_REMINDER',
    SetErrors = 'SET_ERRORS_CREATE_ISSUE_REMINDER',
}

export const enum EEditIssueStatusActions {
    SetIsOpen = 'SET_IS_OPEN_EDIT_ISSUE_STATUS',
    SetIssueId = 'SET_ISSUE_ID_EDIT_ISSUE_STATUS',
    SetFormValues = 'SET_FORM_VALUES_EDIT_ISSUE_STATUS',
    SetErrors = 'SET_ERRORS_EDIT_ISSUE_STATUS',
}

export const enum EEditIssuePricesActions {
    SetIsOpen = 'SET_IS_OPEN_EDIT_ISSUE_PRICES',
    SetIsOpenClient = 'SET_IS_OPEN_EDIT_ISSUE_PRICES_CLIENT',
    SetIssueId = 'SET_ISSUE_ID_EDIT_ISSUE_PRICES',
    SetStaticPrices = 'SET_STATIC_PRICES_EDIT_ISSUE_PRICES',
    SetFormValues = 'SET_FORM_VALUES_EDIT_ISSUE_PRICES',
    SetErrors = 'SET_ERRORS_EDIT_ISSUE_PRICES',
    SetSearchPrices = 'SET_SEARCH_PRICES_EDIT_ISSUE_PRICES',
    SetAllPrices = 'SET_ALL_PRICES_EDIT_ISSUE_PRICES',
    SetPriceRecipient = 'SET_PRICE_RECIPIENT_EDIT_ISSUE_PRICES',
    SetDialogTitle = 'SET_DIALOG_TITLE_EDIT_ISSUE_PRICES',
}

export const enum EIssueInvoicingNotificationsActions {
    SetSmsIsOpen = 'SET_SMS_IS_OPEN_INVOICING_NOTIFICATIONS',
    SetEmailIsOpen = 'SET_EMAIL_IS_OPEN_INVOICING_NOTIFICATIONS',
    SetDataInvoicingNotifications = 'SET_DATA_INVOICING_NOTIFICATIONS',
    SetInvoicingNotifications = 'SET_INVOICING_NOTIFICATIONS',
}

export const enum EGenerateXlsActions {
    SetFormValues = 'SET_FORM_VALUES_GENERATE_XLS',
    SetErrors = 'SET_ERRORS_GENERATE_XLS',
    SetIsOpen = 'SET_IS_OPEN_GENERATE_XLS',
}

export const enum EModelsActions {
    SetModel = 'SET_MODEL',
    SetModelDetail = 'SET_MODEL_DETAIL',
    SetModels = 'SET_MODELS',
}

export const enum EPricesActions {
    SetPrices = 'SET_PRICES',
    SetPrice = 'SET_PRICE',
    SetIsPriceFileLoading = 'IS_PRICE_FILE_LOADING',
    SetFilters = 'SET_FILTERS',
    SetPricesTab = 'SET_PRICES_TAB',
    SetPagination = 'SET_PAGINATION',
    SetIsPriceLoading = 'SET_IS_PRICE_LOADING',
    SetIsOpenDeletePrice = 'SET_IS_OPEN_DELETE_PRICE',
}

export const enum EPricesServicesActions {
    SetServices = 'SET_SERVICES',
    SetService = 'SET_SERVICE',
    SetServicesTab = 'SET_SERVICES_TAB',
    SetIsServiceLoading = 'SET_IS_SERVICE_LOADING',
    SetIsOpenDeletePricesServices = 'SET_IS_OPEN_DELETE_PRICES_SERVICES',
}

export const enum EPartnersActions {
    SetPartners = 'SET_PARTNERS',
}

export const enum ECompaniesActions {
    SetCompanies = 'SET_COMPANIES',
}

export const enum EDictsActions {
    SetAddresses = 'SET_ADDRESSES_DICTS',
    SetServiceAreas = 'SET_SERVICE_AREAS_DICTS',
}

export const enum ERatiosActions {
    SetCategories = 'SET_CATEGORIES_RATIOS',
    SetPartners = 'SET_PARTNERS_RATIOS',
}

export const enum ECategoriesRatiosFilterActions {
    SetCategoriesFilter = 'SET_CATEGORIES_RATIOS_FILTER',
    SetSelectedCategories = 'SET_SELECTED_CATEGORIES_RATIOS_FILTER',
    SetSelectedCategoriesForWorkers = 'SET_SELECTED_CATEGORIES_RATIOS_FILTER_FOR_WORKERS',
    SetSearch = 'SET_SEARCH_CATEGORIES_RATIOS_FILTER',
}

export const enum EPartnersRatiosFilterActions {
    SetPartnersFilter = 'SET_PARTNERS_RATIOS_FILTER',
    SetSelectedPartner = 'SET_SELECTED_PARTNERS_RATIOS_FILTER',
    SetSearch = 'SET_SEARCH_PARTNERS_RATIOS_FILTER',
}

export const enum EEditRatioActions {
    SetIsOpen = 'SET_IS_OPEN_EDIT_RATIO',
    SetRatioId = 'SET_CATEGORY_ID_EDIT_RATIO',
    SetFormValues = 'SET_FORM_VALUES_EDIT_RATIO',
}

export const enum ECreateIssuePartnerActions {
    SetSelectedPrices = 'SET_SELECTED_PRICES_PARTNER',
    SetFormValues = 'SET_FORM_VALUES_CREATE_ISSUE_PARTNER',
    SetStep = 'SET_STEP_CREATE_ISSUE_PARTNER_FORM',
    SetErrors = 'SET_ERRORS_CREATE_ISSUE_PARTNER',
}

export const enum EDialogActions {
    SetDialog = 'SET_DIALOG',
    CancelDialog = 'CANCEL_DIALOG',
    SetLoading = 'SET_LOADING_DIALOG',
}

export const enum ESideBarActions {
    SetIsOpen = 'SET_IS_OPEN_SIDEBAR',
}

export const enum ESearchActions {
    SetItems = 'SET_ITEMS_SEARCH',
    SetFilters = 'SET_FILTERS_SEARCH',
    SetIndicies = 'SET_INDICIES_SEARCH',
    SetText = 'SET_TEXT_SEARCH',
    SetIsOpen = 'SET_IS_OPEN_SEARCH',
}

export const enum EChatGroupByUsersActions {
    PutChatGroupByUsers = 'PUT_CHAT_GROUP_BY_USERS',
    SetChatGroupByUsersFilters = 'SET_CHAT_GROUP_BY_USERS_FILTERS',
    SetChatGroupByUsers = 'SET_CHAT_GROUP_BY_USERS',
    SetChatGroupByUsersIsLoading = 'SET_CHAT_GROUP_BY_USERS_IS_LOADING',
    SetChatGroupByUsersPagination = 'SET_CHAT_GROUP_BY_USERS_PAGINATION',
    InsertChatGroupByUsers = 'INSERT_CHAT_GROUP_BY_USERS',
    SetShouldReload = 'SET_SHOULD_RELOAD',
    ResetChatGroupByUsers = 'RESET_CHAT_GROUP_BY_USERS',
}

export const enum EChatGroupByUserActions {
    SetChatGroupByUserDetail = 'SET_CHAT_GROUP_BY_USER_DETAIL',
    UpdateChatGroupByUserDetail = 'UPDATE_CHAT_GROUP_BY_USER_DETAIL',
    SetChatGroupByUserFilters = 'SET_CHAT_GROUP_BY_USER_FILTERS',
}

export const enum EChatsActions {
    SetChats = 'SET_CHATS',
    SetIsChatsTableLoader = 'SET_IS_CHATS_TABLE_LOADER',
    SetChatsPagination = 'SET_CHATS_PAGINATION',
    SetChatsFilters = 'SET_CHATS_FILTERS',
    SetChatsSortOptions = 'SET_CHATS_SORT_OPTIONS',
    SetTotalUnseenCount = 'SET_CHATS_TOTAL_UNSEEN_COUNT',
    ResetChats = 'RESET_CHATS',
    SetTotalUnseenIssuesCount = 'SET_CHATS_TOTAL_UNSEEN_COUNT_ISSUES',
}

export const enum EChatsInfiniteActions {
    PutChatsInfinite = 'PUT_CHATS_INFINITE',
    SetChatsInfinite = 'SET_CHATS_INFINITE',
    SetIsLoadingChatsInfinite = 'SET_IS_LOADING_CHATS_INFINITE',
    SetChatsPaginationInfinite = 'SET_CHATS_PAGINATION_INFINITE',
    SetChatsFiltersInfinite = 'SET_CHATS_FILTERS_INFINITE',
    SetChatsSortOptionsInfinite = 'SET_CHATS_SORT_OPTIONS_INFINITE',
    ResetChatsInfinite = 'RESET_CHATS_INFINITE',
    InsertChatsInfinite = 'INSERT_CHATS_INFINITE',
}

export const enum EChatActions {
    ChatReset = 'CHAT_RESET',
    SetChatDetail = 'SET_CHAT_DETAIL',
    SetChatAuthorClient = 'SET_CHAT_AUTHOR_CLIENT',
    SetChatAuthorWorker = 'SET_CHAT_AUTHOR_WORKER',
    SetChatIssueHistoryForClient = 'SET_CHAT_ISSUE_HISTORY_FOR_CLIENT',
    SetChatIssueHistoryForWorker = 'SET_CHAT_ISSUE_HISTORY_FOR_WORKER',
    SetChatHistory = 'SET_CHAT_HISTORY',
    AddChatHistoryItem = 'ADD_CHAT_HISTORY_ITEM',
    DeleteChatHistoryItem = 'DELETE_CHAT_HISTORY_ITEM',
    MarkAsViewedChatHistoryItems = 'MARK_AS_VIEWED_CHAT_HISTORY_ITEMS',
    SetChatParticipants = 'SET_CHAT_PARTICIPANTS',
}

export const enum EWorkersActions {
    WorkersReset = 'WORKERS_RESET',
    SetWorkers = 'SET_WORKERS',
    SetSearch = 'SET_SEARCH',
    SetSelectedWorkersIds = 'SET_SELECTED_WORKERS_IDS',
    SetIsWorkersTableLoader = 'SET_IS_WORKERS_TABLE_LOADER',
    SetWorkersPagination = 'SET_WORKERS_PAGINATION',
    SetWorkersFilters = 'SET_WORKERS_FILTERS',
    SetWorkersSortOptions = 'SET_WORKERS_SORT_OPTIONS',
    SetWorkersCount = 'SET_WORKERS_COUNT',
}

export const enum EWorkerActions {
    WorkerReset = 'WORKER_RESET',
    SetWorkerDetail = 'SET_WORKER_DETAIL',
    SetWorkerAvatar = 'SET_WORKER_AVATAR',
    SetCanceledIssueHistory = 'SET_CANCELED_ISSUE_HISTORY',
    SetSolvedIssueHistory = 'SET_SOLVED_ISSUE_HISTORY',
    SetWorkerFIO = 'SET_WORKER_FIO',
    SetWorkerSchedule = 'SET_WORKER_SCHEDULE',
    SetWorkerSpecializations = 'SET_WORKER_SPECIALIZATIONS',
    SetWorkerServiceAreas = 'SET_WORKER_SERVICE_AREAS',
    SetSelectedTab = 'SET_WORKER_SELECTED_TAB',
    SetWorkerSelectedSpecializations = 'SET_WORKER_SELECTED_SPECIALIZATIONS',
    SetWorkerMoySklad = 'SET_WORKER_MOYSKLAD',
    SetWorkerMoySkladUnbind = 'SET_WORKER_MOYSKLAD_UNBIND',
    SetWorkerMoySkladAssortment = 'SET_WORKER_MOYSKLAD_ASSORTMENT',
}

export const enum EEditWorkerFIOActions {
    SetIsOpen = 'SET_IS_OPEN_EDIT_WORKER_FIO',
    SetWorkerId = 'SET_WORKER_ID_WORKER_FIO',
    SetFormValues = 'SET_FORM_VALUES_EDIT_WORKER_FIO',
    SetErrors = 'SET_ERRORS_EDIT_WORKER_FIO',
}

export const enum EEditWorkerScheduleActions {
    SetIsOpen = 'SET_IS_OPEN_EDIT_WORKER_SCHEDULE',
    SetWorkerId = 'SET_WORKER_ID_WORKER_SCHEDULE',
    SetFormValues = 'SET_FORM_VALUES_EDIT_WORKER_SCHEDULE',
    SetErrors = 'SET_ERRORS_EDIT_WORKER_SCHEDULE',
}

export const enum EEditWorkerInfoActions {
    SetIsOpen = 'SET_IS_OPEN_EDIT_WORKER_INFO',
    SetIsLoading = 'SET_IS_LOADING_EDIT_WORKER_INFO',
    SetEducationType = 'SET_EDUCATION_TYPE_EDIT_WORKER_INFO',
    SetEducationalProgram = 'SET_EDUCATIONAL_PROGRAM_EDIT_WORKER_INFO',
    SetAdditionalEducation = 'SET_ADDITIONAL_EDUCATION_EDIT_WORKER_INFO',
    SetInstitutionName = 'SET_INSTITUTION_NAME_EDIT_WORKER_INFO',
    SetErrors = 'SET_ERRORS_EDIT_WORKER_INFO',
}

export const enum EEditWorkerSpecializationsActions {
    SetIsOpen = 'SET_IS_OPEN_EDIT_WORKER_SPECIALIZATIONS',
    SetWorkerId = 'SET_WORKER_ID_WORKER_SPECIALIZATIONS',
    SetFormValues = 'SET_FORM_VALUES_EDIT_WORKER_SPECIALIZATIONS',
    SetErrors = 'SET_ERRORS_EDIT_WORKER_SPECIALIZATIONS',
}

export const enum ECreateWorkerMoySkladActions {
    SetIsOpen = 'SET_IS_OPEN_CREATE_WORKER_MOYSKLAD',
    SetFormValues = 'SET_FORM_VALUES_CREATE_WORKER_MOYSKLAD',
    SetWorkerId = 'SET_WORKER_ID_WORKER_MOYSKLAD',
    SetErrors = 'SET_ERRORS_CREATE_WORKER_MOYSKLAD',
}

export const enum EBindWorkerMoySkladActions {
    SetIsOpen = 'SET_IS_OPEN_BIND_WORKER_MOYSKLAD',
    SetSearchResponse = 'SET_SEARCH_RESPONSE_WORKER_MOYSKLAD',
    SetFormValues = 'SET_FORM_VALUES_BIND_WORKER_MOYSKLAD',
}

export const enum EUnbindWorkerMoySkladActions {
    SetFormValues = 'SET_FORM_VALUES_UNBIND_WORKER_MOYSKLAD',
}

export const enum EEditWorkerServiceAreasActions {
    SetIsOpen = 'SET_IS_OPEN_EDIT_WORKER_SERVICE_AREAS',
    SetWorkerId = 'SET_WORKER_ID_WORKER_SERVICE_AREAS',
    SetFormValues = 'SET_FORM_VALUES_EDIT_WORKER_SERVICE_AREAS',
}

export const enum ECandidatesModalActions {
    SetIsOpen = 'SET_IS_OPEN_CANDIDATES_MODAL',
    SetSelectedWorkers = 'SET_SELECTED_WORKERS_CANDIDATES_MODAL',
    SetFilters = 'SET_SET_FILTERS_CANDIDATES_MODAL',
    SetIsLoading = 'SET_IS_LOADING_CANDIDATES_MODAL',
}

export const enum ECentrifugeActions {
    SetCentrifuge = 'SET_CENTRIFUGE',
    SetIsConnect = 'SET_IS_CONNECT',
}

export const enum ECreateGroupActions {
    SetCreateGroupIsOpen = 'SET_CREATE_GROUP_IS_OPEN',
    SetWorkersList = 'SET_CREATE_GROUP_WORKERS_LIST',
}

export const enum EEditCategoryActions {
    SetIsOpen = 'SET_IS_OPEN_EDIT_CATEGORY',
    SetCategoryId = 'SET_CATEGORY_ID_EDIT_CATEGORY',
    SetFormValues = 'SET_FORM_VALUES_EDIT_CATEGORY',
    SetErrors = 'SET_ERRORS_EDIT_CATEGORY',
}

export const enum EPriceDialogActions {
    SetIsOpen = 'SET_IS_OPEN_PRICE_DIALOG',
    SetPriceId = 'SET_PRICE_ID_PRICE_DIALOG',
    SetFormValues = 'SET_FORM_VALUES_PRICE_DIALOG',
    SetCategories = 'SET_CATEGORIES_PRICE_DIALOG',
    SetCategoriesPagination = 'SET_CATEGORIES_PAGINATION_PRICE_DIALOG',
    SetAdditionalPrices = 'SET_ADDITIONAL_PRICES_PRICE_DIALOG',
    SetAdditionalPricesPagination = 'SET_ADDITIONAL_PRICES_PAGINATION_PRICE_DIALOG',
    SetErrors = 'SET_ERRORS_PRICE_DIALOG',
    SetSelectedCategories = 'SET_SELECTED_CATEGORIES_PRICE_DIALOG',
    SetSelectedAdditionalPrices = 'SET_SELECTED_ADDITIONAL_PRICES_PRICE_DIALOG',
}

export const enum ETagsActions {
    SetTags = 'SET_TAGS',
}

export const enum EArchiveActions {
    ArchiveReset = 'ARCHIVE_RESET',
    SetSelectedTab = 'ARCHIVE_SET_SELECTED_TAB',

    SetIssues = 'ARCHIVE_SET_ISSUES',
    SetIsIssuesTableLoader = 'ARCHIVE_SET_IS_TABLE_LOADER_ISSUES',
    SetIssuesPagination = 'ARCHIVE_SET_ISSUES_PAGINATION',
    SetIssuesFilters = 'ARCHIVE_SET_ISSUE_FILTERS',
    SetIssuesTableHead = 'ARCHIVE_SET_ISSUES_TABLE_HEAD',

    SetWorkers = 'ARCHIVE_SET_WORKERS',
    SetIsWorkersTableLoader = 'ARCHIVE_SET_IS_WORKERS_TABLE_LOADER',
    SetWorkersPagination = 'ARCHIVE_SET_WORKERS_PAGINATION',
    SetWorkersFilters = 'ARCHIVE_SET_WORKERS_FILTERS',
    SetWorkersSortOptions = 'ARCHIVE_SET_WORKERS_SORT_OPTIONS',
}

export const enum EHistoryActions {
    SetHistory = 'SET_HISTORY',
}

export const enum EGroupsActions {
    SetGroups = 'SET_GROUPS',
    SetGroup = 'SET_GROUP',
    SetIsOpenEditGroup = 'SET_IS_OPEN_EDIT_GROUP',
}

export const enum EToastActions {
    SetToast = 'SET_TOAST',
    SetCancelToast = 'SET_CANCEL_TOAST',
}

export const enum EReminderActions {
    SetReminders = 'SET_REMINDERS',
    SetReminder = 'SET_REMINDER',
    SetIsOpenDelete = 'SET_IS_OPEN_Delete_REMINDER',
}

export const enum EEditIssueReminderActions {
    SetIsOpen = 'SET_IS_OPEN_EDIT_REMINDER',
    SetFormValues = 'SET_FORM_VALUES_EDIT_ISSUE_REMINDER',
    SetErrors = 'SET_ERRORS_EDIT_ISSUE_REMINDER',
}
export const enum EPriceServicesDialogActions {
    SetIsOpen = 'SET_IS_OPEN_PRICE_SERVICES_DIALOG',
    SetFormValues = 'SET_FORM_VALUES_PRICE_SERVICES_DIALOG',
    SetErrors = 'SET_ERRORS_PRICE_SERVICES_DIALOG',
}

export const enum EMapActions {
    SetFilters = 'SET_FILTERS_MAP',
    SetAreas = 'SET_AREAS_MAP',
    SetIssues = 'SET_ISSUES_MAP',
    SetWorkers = 'SET_WORKERS_MAP',
    SetMapTab = 'SET_MAP_TAB_MAP',
    SetIsLoading = 'SET_IS_LOADING_MAP',

    SetSelectedIssues = 'SET_SELECTED_ISSUES_MAP',
    SetSelectedWorkers = 'SET_SELECTED_WORKERS_MAP',
    SetSelectedArea = 'SET_SELECTED_AREA_MAP',

    SetPin = 'SET_PIN_MAP',
    SetSelectedPinIssue = 'SET_SELECTED_PIN_ISSUE_MAP',
    SetSelectedPinWorker = 'SET_SELECTED_PIN_WORKER_MAP',
}

export const enum EPartsActions {
    SetParts = 'SET_PARTS',
    SetPart = 'SET_PART',
    SetPartFilters = 'SET_PART_FILTERS',
    SetPartPagination = 'SET_PART_PAGINATION',
}

export const enum EOrdersActions {
    SetOrders = 'SET_ORDERS',
    SetOrder = 'SET_ORDER',
    SetIsOpenNextStatusOrder = 'SET_IS_OPEN_NEXT_STATUS_ORDER',
    SetIsOpenCancelOrder = 'SET_IS_OPEN_CANCEL_ORDER',
    SetIsOrdersLoading = 'SET_IS_LOADING_ORDERS',
    SetSelectedOrders = 'SET_SELECTED_ORDERS',
}

export const enum ECreatePartActions {
    SetFormValues = 'SET_FORM_VALUES_CREATE_PART',
    SetErrors = 'SET_ERRORS_CREATE_PART',
    SetIsOpenCreatePart = 'SET_IS_OPEN_CREATE_PART',
}

export const enum ECreateOrderActions {
    SetFormValues = 'SET_FORM_VALUES_CREATE_ORDER',
    SetErrors = 'SET_ERRORS_CREATE_ORDER',
    SetIsOpenCreateOrder = 'SET_IS_OPEN_ADD_ORDER',
}
