import LoadingButton from '@mui/lab/LoadingButton';
import { Button } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { EIssueStatus } from '../../../../../../../enums/issues.enum';
import { ECommissionsType } from '../../../../../../../enums/prices.enum';
import { IError } from '../../../../../../../interfaces/edit/editIssuePrices';
import {
    editIssuePricesSolvedThunk,
    editIssuePricesThunk,
    setErrorsEditIssuePricesAction,
} from '../../../../../../../store/actions/edit/editIssuePrices';
import { useAppDispatch } from '../../../../../../../store/hooks';
import {
    errorsEditIssuePricesSelector,
    formValuesEditIssuePricesSelector,
    issueIdEditIssuePricesSelector,
    staticPricesEditIssuePricesSelector,
} from '../../../../../../../store/selectors/edit/editIssuePricesSelectors';
import { issueSelector } from '../../../../../../../store/selectors/issuesSelectors';
import defaultErrorCallback from '../../../../../../../utils/helpers/defaultErrorCallback';
import { getDiscount } from '../../../../../../../utils/helpers/getDiscount';
import { getErrorString } from '../../../../../../../utils/helpers/getErrorString';
import './FooterForm.scss';

interface IProps {
    onClose: () => void;
}

function FooterForm({ onClose }: IProps) {
    const dispatch = useAppDispatch();

    const issue = useSelector(issueSelector);
    const issueId = useSelector(issueIdEditIssuePricesSelector);
    const staticPrices = useSelector(staticPricesEditIssuePricesSelector);
    const formValues = useSelector(formValuesEditIssuePricesSelector);
    const errors = useSelector(errorsEditIssuePricesSelector);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isBtnDisabled, setIsBtnDisabled] = useState<boolean>(false);

    /*
		TODO: В дальнейшем нужно придумать валидацию с бэком...
		Валидация для редактирования цен в статусе "Выполнено"
	 */

    const validator = useCallback(() => {
        const errs: IError[] = [];
        dispatch(setErrorsEditIssuePricesAction(null));
        if (formValues?.prices?.length) {
            formValues?.prices?.forEach((price, index) => {
                switch (true) {
                    case price.price_recipient === 'partner':
                        return;
                    case !price.value:
                        return;
                    case price.discount_type === ECommissionsType.Percentage &&
                        !price.id &&
                        (!price.discount || price.discount < 100):
                        errs.push({
                            id: index,
                            message: 'Размер скидки должен быть 100%',
                            value: price?.value,
                        });
                        break;
                    case price.discount_type === ECommissionsType.Fixation &&
                        !price.id &&
                        (!price.discount || price.discount < price.value!):
                        errs.push({
                            id: index,
                            message: 'Размер скидки должен быть равен сумме прайса',
                            value: price?.value,
                        });
                        break;
                    default:
                        break;
                }
            });
            dispatch(setErrorsEditIssuePricesAction(errs));
        }

        return !Object.values(errs).some((val) => val);
    }, [formValues]);

    useEffect(() => {
        if (errors) {
            validator();
        }

        setIsBtnDisabled(!formValues);
    }, [formValues]);

    async function submitPricesFormHandler() {
        if (!issueId || !formValues) return;

        try {
            setIsLoading(true);
            const newPrices = [
                ...(formValues?.prices?.map((pr) => ({
                    price_id: pr.price_id!.value!,
                    discount: getDiscount({ discount: pr.discount, discount_type: pr.discount_type }),
                    value: pr.value || 0,
                    price_recipient: pr.price_recipient,
                    discount_type: pr.discount_type,
                })) || []),
                ...(staticPrices || []),
            ];
            await dispatch(editIssuePricesThunk(issueId, newPrices));
            onClose();
        } catch (err) {
            defaultErrorCallback({ errorMessage: getErrorString({ err }) });
        } finally {
            setIsLoading(false);
        }
    }

    async function submitPricesFormSolvedHandler() {
        if (!issueId || !formValues) return;

        try {
            if (validator()) {
                setIsLoading(true);
                const newPrices = [
                    ...(formValues?.prices?.map((pr) => ({
                        price_id: pr.price_id!.value!,
                        discount: getDiscount({ discount: pr.discount, discount_type: pr.discount_type }),
                        value: pr.value || 0,
                        price_recipient: pr.price_recipient,
                        discount_type: pr.discount_type,
                    })) || []),
                    ...(staticPrices || []),
                ];
                await dispatch(editIssuePricesSolvedThunk(issueId, newPrices));
                onClose();
            }
        } catch (err) {
            defaultErrorCallback({ errorMessage: getErrorString({ err }) });
        } finally {
            setIsLoading(false);
        }
    }

    async function submitFormHandler() {
        switch (issue?.status) {
            case EIssueStatus.Solved:
                await submitPricesFormSolvedHandler();
                break;
            default:
                await submitPricesFormHandler();
                break;
        }
    }

    return (
        <div className="buttons-wrapper">
            <Button
                variant="contained"
                color="inherit"
                onClick={onClose}
            >
                Отмена
            </Button>

            <LoadingButton
                variant="contained"
                loading={isLoading}
                disabled={isBtnDisabled}
                onClick={submitFormHandler}
            >
                Сохранить
            </LoadingButton>
        </div>
    );
}

export default FooterForm;
