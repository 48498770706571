import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import TextField from '../../../../../components/inputs/TextField';
import { TErrors } from '../../../../../interfaces';
import { setIsOpenCommentDialogAction } from '../../../../../store/actions/issues';
import { useAppDispatch } from '../../../../../store/hooks';
import { issuesIsOpenCommentDialogSelector } from '../../../../../store/selectors/issuesSelectors';
import './CommentDialog.scss';

interface IProps {
    isEdit: boolean;
    defaultValue: string;
    addOrEditComment: (comment: string) => void;
}

function CommentDialog({ isEdit, defaultValue, addOrEditComment }: IProps) {
    const dispatch = useAppDispatch();

    const ref = useRef<HTMLTextAreaElement>(null);

    const [errors, setErrors] = useState<TErrors | null>(null);
    const [comment, setComment] = useState<string>('');

    const isOpen = useSelector(issuesIsOpenCommentDialogSelector);

    function onClose() {
        dispatch(setIsOpenCommentDialogAction(false));
    }

    function changeComment(e: React.ChangeEvent<HTMLInputElement>) {
        setComment(e.target.value);
        setErrors(!e.target.value.trim() ? { comment: { message: 'Обязательное поле' } } : null);
    }

    useEffect(() => {
        if (isOpen) {
            setComment(defaultValue);
            setErrors(!defaultValue.length && isEdit ? { comment: { message: 'Обязательное поле' } } : null);
        }
    }, [isOpen]);

    ref.current?.focus();

    return (
        <Dialog
            className="comment-dialog"
            open={isOpen}
            maxWidth={'sm'}
            fullWidth
            scroll="body"
            disableEscapeKeyDown
            onClose={onClose}
        >
            <DialogTitle>
                <Box className="comment-dialog-title">
                    <TextsmsOutlinedIcon
                        color="primary"
                        sx={{ marginRight: '30px' }}
                        fontSize="large"
                    />
                    <Typography variant="h6">{isEdit ? 'Изменение комментария' : 'Новый комментарий'}</Typography>
                </Box>
            </DialogTitle>
            <DialogContent className="content">
                <div className="subtitle">Данный комментарий увидят только пользователи админки</div>
                <TextField
                    className="comment-field"
                    fullWidth
                    errors={errors}
                    params={{ multiline: true, inputRef: ref }}
                    defaultValue={defaultValue}
                    onChange={changeComment}
                    labelText="Комментарий"
                    name="comment"
                    sxStyle={{ minHeight: 100, marginTop: 2, marginBottom: 2 }}
                />
                <div className="buttons">
                    <Button
                        onClick={onClose}
                        color="inherit"
                        variant="contained"
                    >
                        Отмена
                    </Button>
                    <LoadingButton
                        disabled={!!errors || !comment}
                        variant="contained"
                        sx={{ ml: 1 }}
                        onClick={() => addOrEditComment(comment)}
                    >
                        Отправить
                    </LoadingButton>
                </div>
            </DialogContent>
        </Dialog>
    );
}

export default CommentDialog;
