import { EIssueTriggers } from 'enums/issues.enum';
import { TDeposit } from 'pages/accounting/deposits/store/types';
import { Params } from 'types';

import { AxiosPromise, AxiosRequestConfig } from 'axios';
import { ICreateIssueCandidatesReq } from 'interfaces/candidatesModal';
import { ICreateIssueReq } from 'interfaces/create/createIssue';
import { ICreateIssuePartnerReq } from 'interfaces/create/createIssuePartner';
import { IPutIssuePricesReq } from 'interfaces/edit/editIssuePrices';
import { IEditIssueStatusReq } from 'interfaces/edit/editIssueStatus';
import { IIssueCancelationReason, IPutCancelIssueStatusReq } from 'interfaces/edit/editIssueStatusToCancel';
import {
    ICreateInvoicingNotificationsReq,
    IInvoicingNotification,
    INotificationDataResp,
} from 'interfaces/invoicingNotifications';
import {
    changeIssueHelpTypeReq,
    changeIssuesMasterSearchReq,
    changeIssueWarrantyTypeReq,
    changeIssueSaleTypeReq,
    IAddressParts,
    IComment,
    IIssue,
    INoteIssue,
    IPutIssueAppointmentReq,
    IPutIssueArchiveReq,
    IPutIssuePaymentMethodReq,
    TCanceledIssuesResp,
    TCounterIssuesReq,
    TCounterIssuesResp,
    TIssuesResp,
    TSolvedIssuesResp,
    TDocumentResp,
} from 'interfaces/issues';
import { IPaginationResp } from 'interfaces/pagination';

import axiosFetch from './axiosFetch';

export default class IssuesAPI {
    /* Список заявок */
    getIssues(queries?: string) {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/issues${queries || ''}`,
            isAuthorization: true,
        }) as AxiosPromise<TIssuesResp>;
    }

    /* Список заявок */
    getIssuesNew(params: any) {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/issues`,
            params,
            isAuthorization: true,
        }) as AxiosPromise<TIssuesResp>;
    }

    /* Заявка */
    getIssue(id: string | number) {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/issues/${id}`,
            isAuthorization: true,
        }) as AxiosPromise<IIssue>;
    }

    /* Создание заявки */
    createIssue(issue: ICreateIssueReq) {
        return axiosFetch({
            method: 'post',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/issues`,
            isAuthorization: true,
            data: issue,
        }) as AxiosPromise<IIssue>;
    }

    /* Создание заявки партнёра */
    createPartnerIssue(issue: ICreateIssuePartnerReq) {
        return axiosFetch({
            method: 'post',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/issues/partner`,
            isAuthorization: true,
            data: issue,
        }) as AxiosPromise<IIssue>;
    }

    /* Счётчики заявок по статусам */
    getCounters(data: TCounterIssuesReq) {
        return axiosFetch({
            method: 'post',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/issues/count`,
            isAuthorization: true,
            data,
        }) as AxiosPromise<TCounterIssuesResp>;
    }

    /* Комментарии */
    addComment(issueId: string, comment: { comment: string }) {
        return axiosFetch({
            method: 'post',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/issues/${issueId}/comments`,
            isAuthorization: true,
            data: comment,
        }) as AxiosPromise<IComment>;
    }

    editComment(issueId: string, commentId: string, comment: { comment: string }) {
        return axiosFetch({
            method: 'put',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/issues/${issueId}/comments/${commentId}`,
            isAuthorization: true,
            data: comment,
        }) as AxiosPromise<IComment>;
    }

    deleteComment(issueId: string, commentId: string) {
        return axiosFetch({
            method: 'delete',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/issues/${issueId}/comments/${commentId}`,
            isAuthorization: true,
        }) as AxiosPromise<any>;
    }

    getCanceledIssueHistoryForWorker(workerId: string) {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/issues/cancellationHistory/${workerId}`,
            isAuthorization: true,
        }) as AxiosPromise<TCanceledIssuesResp>;
    }

    getSolvedIssueHistoryForWorker(workerId: string) {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/issues/solvedHistory/${workerId}`,
            isAuthorization: true,
        }) as AxiosPromise<TSolvedIssuesResp>;
    }

    putIssue(issueId: string, data?: Partial<IIssue>) {
        return axiosFetch({
            method: 'put',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/issues/${issueId}`,
            data,
            isAuthorization: true,
        }) as AxiosPromise<IIssue>;
    }

    putIssueAppointment(issueId: string, data: IPutIssueAppointmentReq) {
        return axiosFetch({
            method: 'put',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/issues/${issueId}/changeAppointment`,
            data,
            isAuthorization: true,
        }) as AxiosPromise<IIssue>;
    }

    putIssueAddress(issueId: string, data: Partial<IAddressParts>) {
        return axiosFetch({
            method: 'put',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/issues/${issueId}/address`,
            data,
            isAuthorization: true,
        }) as AxiosPromise<IIssue>;
    }

    putIssueStatus(issueId: string, newStatus: string, comment: string | null | undefined, data?: Partial<IIssue>) {
        return axiosFetch({
            method: 'put',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/issues/${issueId}/${newStatus}`,
            data: { ...data, comment },
            isAuthorization: true,
        }) as AxiosPromise<IIssue>;
    }

    putCancelIssueStatus(issueId: string, data: IPutCancelIssueStatusReq) {
        return axiosFetch({
            method: 'put',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/issues/${issueId}/cancel`,
            data,
            isAuthorization: true,
        }) as AxiosPromise<IIssue>;
    }

    getIssueCancelationReasons(queries: string) {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/issues/cancelationReasons${
                queries || ''
            }`,
            isAuthorization: true,
        }) as AxiosPromise<IIssueCancelationReason[]>;
    }

    putIssuePrices(issueId: string, data: IPutIssuePricesReq[]) {
        return axiosFetch({
            method: 'put',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/issues/${issueId}/price`,
            data,
            isAuthorization: true,
        }) as AxiosPromise<IIssue>;
    }

    /* Редактировани цена в статусе "Выполнено"*/
    putIssuePricesSolved(issueId: string, data: IPutIssuePricesReq[]) {
        return axiosFetch({
            method: 'put',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/issues/${issueId}/price/solved`,
            data,
            isAuthorization: true,
        }) as AxiosPromise<IIssue>;
    }

    issueChargePost(issueId: string) {
        return axiosFetch({
            method: 'post',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/issues/${issueId}/charge`,
            data: {},
            isAuthorization: true,
        }) as AxiosPromise<IIssue>;
    }

    createIssueCandidates(issueId: string, data: ICreateIssueCandidatesReq) {
        return axiosFetch({
            method: 'post',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/issues/${issueId}/${EIssueTriggers.Assignee}`,
            data,
            isAuthorization: true,
        }) as AxiosPromise<IIssue>;
    }

    editIssueStatus(issueId: string, data: IEditIssueStatusReq) {
        return axiosFetch({
            method: 'put',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/issues/${issueId}/changeStatus`,
            data,
            isAuthorization: true,
        }) as AxiosPromise<IIssue>;
    }

    /* Список созданных уведомлений */
    getInvoicingNotifications(issueId: string, queries?: string) {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${
                process.env.REACT_APP_API_VERSION
            }/issues/invoicingNotifications/${issueId}${queries || ''}`,
            isAuthorization: true,
        }) as AxiosPromise<IInvoicingNotification[]>;
        /* TODO:Выяснить почему тут было IGetInvoicingNotificationsResp */
    }

    /* Данные для создания уведомления об оплате клиенту */
    getInvoicingNotificationData(issueId: string) {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/issues/invoicingNotifications/${issueId}/notificationData`,
            isAuthorization: true,
        }) as AxiosPromise<INotificationDataResp>;
    }

    /* Получение заметок мастера по заявке */
    getNotesMasterIssue(issueId: string) {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/issues/${issueId}/notes`,
            isAuthorization: true,
        }) as AxiosPromise<INoteIssue[]>;
    }

    /* Педаль создания уведомления об оплате клиенту */
    createInvoicingNotifications(issueId: string, data: ICreateInvoicingNotificationsReq) {
        return axiosFetch({
            method: 'post',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/issues/invoicingNotifications`,
            data,
            isAuthorization: true,
        }) as AxiosPromise<IInvoicingNotification>;
    }

    /* Изменение способа оплаты */
    putPaymentMethod(issueId: string, data: IPutIssuePaymentMethodReq) {
        return axiosFetch({
            method: 'PUT',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/issues/${issueId}/paymentMethod`,
            data,
            isAuthorization: true,
        }) as AxiosPromise<IIssue>;
    }

    /* Перевод заявки в архив */
    putIssueArchive(issueId: string, data?: IPutIssueArchiveReq) {
        return axiosFetch({
            method: 'put',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/issues/${issueId}/archive`,
            data,
            isAuthorization: true,
        }) as AxiosPromise<IIssue>;
    }

    /* Изменение статуса на "Поиск мастера" в сразу нескольких заявках */
    changeIssuesMasterSearch(data: changeIssuesMasterSearchReq) {
        return axiosFetch({
            method: 'post',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/issues/master_search_many`,
            data,
            isAuthorization: true,
        }) as AxiosPromise;
    }

    /* Изменение типа услуги в заявке */
    changeIssueHelpType(issueId: string, data: changeIssueHelpTypeReq) {
        return axiosFetch({
            method: 'put',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/issues/${issueId}/helpType`,
            data,
            isAuthorization: true,
        }) as AxiosPromise<IIssue>;
    }

    /* Изменение гарантии заявке */
    changeIssueWarranty(issueId: string, data: changeIssueWarrantyTypeReq) {
        return axiosFetch({
            method: 'put',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/issues/${issueId}/warrantyMark`,
            data,
            isAuthorization: true,
        }) as AxiosPromise<IIssue>;
    }

    /* Изменение продажи в заявке */
    changeIssueSale(issueId: string, data: changeIssueSaleTypeReq) {
        return axiosFetch({
            method: 'put',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/issues/${issueId}/saleMark`,
            data,
            isAuthorization: true,
        }) as AxiosPromise<IIssue>;
    }

    /* Загрузка акта НРП */
    uploadActNrp(issueId: string, act_nrp_file_id: string | null) {
        return axiosFetch({
            method: 'put',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/issues/${issueId}/upload_act_nrp`,
            data: { act_nrp_file_id },
            isAuthorization: true,
        }) as AxiosPromise<IIssue>;
    }

    /* История заявок */
    getIssueHistory(data: any, params?: AxiosRequestConfig['params']) {
        return axiosFetch({
            method: 'post',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/issues/issueCustomList`,
            params,
            data,
            isAuthorization: true,
        }) as AxiosPromise<IIssue>;
    }

    getDocuments(params?: Params) {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/documents`,
            params,
            isAuthorization: true,
        }) as AxiosPromise<TDocumentResp>;
    }

    getTransactions(params?: Params) {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/transactions`,
            params,
            isAuthorization: true,
        }) as AxiosPromise<IPaginationResp<any>>;
    }

    getDeposits(params?: Params) {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/transactions/depositRequests`,
            params,
            isAuthorization: true,
        }) as AxiosPromise<IPaginationResp<TDeposit>>;
    }

    getDeposit(id: string) {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/transactions/depositRequests/${id}`,
            isAuthorization: true,
        }) as AxiosPromise<TDeposit>;
    }

    postDeposits(data: any) {
        return axiosFetch({
            method: 'post',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/transactions/depositRequests/review`,
            data,
            isAuthorization: true,
        }) as AxiosPromise<TDeposit>;
    }
}
