import useOutsideClick from 'hooks/useOutsideClick';
import { getCategoriesRatiosFilterThunk } from 'store/actions/categoriesRatiosFilter';
import { useAppDispatch } from 'store/hooks';
import { categoriesCRFSelector, selectedCRFSelector } from 'store/selectors/categoriesRatiosFilterSelectors';
import defaultErrorCallback from 'utils/helpers/defaultErrorCallback';
import { getErrorString } from 'utils/helpers/getErrorString';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button } from '@mui/material';
import cn from 'classnames';
import { ICategoryRatiosFilter } from 'interfaces/categoriesRatiosFilter';
import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Categories } from './Categories';

import './CategoriesRatiosFilter.scss';

interface IProps {
    onChange: (categoriesIds: ICategoryRatiosFilter['id'][] | null) => void;
    isSingleSelect?: boolean;
}

function CategoriesRatiosFilter({ onChange, isSingleSelect }: IProps) {
    const dispatch = useAppDispatch();

    const categories = useSelector(categoriesCRFSelector);
    const selectedCategories = useSelector(selectedCRFSelector);

    const [isOpen, setIOpen] = useState<boolean>(false);

    const wrapperRef = useOutsideClick(() => {
        setIOpen(false);
    });

    useEffect(() => {
        async function handle() {
            try {
                if (isOpen && !categories) await dispatch(getCategoriesRatiosFilterThunk());
            } catch (err) {
                defaultErrorCallback({ errorMessage: getErrorString({ err }) });
            }
        }
        handle();
    }, [isOpen]);

    useEffect(() => {
        const categoriesIds =
            selectedCategories?.map(({ id }) => {
                return id;
            }) || null;

        onChange(categoriesIds);
    }, [selectedCategories]);

    return (
        <div
            ref={wrapperRef}
            className={cn('categories-filter-btn-wrapper', { isOpen })}
        >
            <Button
                variant="contained"
                onClick={() => {
                    setIOpen(!isOpen);
                }}
                endIcon={<ExpandMoreIcon />}
                className={selectedCategories?.length ? 'is-active' : 'btn-filter'}
            >
                Регион{' '}
                {!!selectedCategories?.length && <span style={{ marginLeft: 10 }}>{selectedCategories?.length}</span>}
            </Button>
            <Categories isSingleSelect={isSingleSelect} />
        </div>
    );
}

export default memo(CategoriesRatiosFilter);
