import {
    addWorkerMoySkladThunk,
    setErrorsCreateWorkerMoySkladAction,
    setIsOpenCreateWorkerMoySkladAction,
    setWorkerMoySkladAction,
} from 'store/actions/create/createMoySklad';
import { useAppDispatch } from 'store/hooks';
import { errorsCreateMoySkladSelector, formValuesCreateMoySkladSelector } from 'store/selectors/create/createMoySklad';
import { workerDetailSelector } from 'store/selectors/workerSelectors';
import defaultErrorCallback from 'utils/helpers/defaultErrorCallback';
import { getErrorString } from 'utils/helpers/getErrorString';

import { Button } from '@mui/material';
import { TErrors } from 'interfaces';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import './FooterForm.scss';

function FooterForm() {
    const dispatch = useAppDispatch();
    const formValues = useSelector(formValuesCreateMoySkladSelector);
    const worker = useSelector(workerDetailSelector);
    const errors = useSelector(errorsCreateMoySkladSelector);

    const [isBtnDisabled, setIsBtnDisabled] = useState<boolean>(true);

    const validator = useCallback(() => {
        const errs = {} as TErrors;

        if (!formValues?.name) {
            errs.name = { message: 'Обязательное поле' };
        }

        if (!formValues?.address) {
            errs.address = { message: 'Обязательное поле' };
        }

        dispatch(setErrorsCreateWorkerMoySkladAction(errs));
        return !Object.values(errs).some((val) => val);
    }, [formValues]);

    useEffect(() => {
        if (errors) {
            validator();
        }

        setIsBtnDisabled(!formValues);
    }, [formValues]);

    async function submitFormHandler() {
        if (!worker?.id || !formValues) return;
        if (validator()) {
            try {
                const updatedFormValues = { ...formValues, worker_id: worker?.id };
                const createMoySklad = await dispatch(addWorkerMoySkladThunk(updatedFormValues));
                dispatch(setWorkerMoySkladAction(createMoySklad));
                dispatch(setIsOpenCreateWorkerMoySkladAction(false));
            } catch (err) {
                defaultErrorCallback({ errorMessage: getErrorString({ err }) });
            }
        }
    }

    return (
        <div className="buttons-wrapper">
            <Button
                variant="contained"
                sx={{
                    width: '160px',
                    height: '44px',
                    background: '#0040F1',
                    textTransform: 'none',
                    fontSize: '16px',
                    fontWeight: '600',
                }}
                onClick={submitFormHandler}
                disabled={isBtnDisabled}
            >
                Создать
            </Button>
            <Button
                variant="contained"
                sx={{
                    width: '122px',
                    height: '44px',
                    background: '#FFFFFF',
                    textTransform: 'none',
                    color: '#0040F1',
                    fontSize: '16px',
                    fontWeight: '500',
                    '&:hover': {
                        background: '#e0e0e0',
                    },
                }}
                onClick={() => dispatch(setIsOpenCreateWorkerMoySkladAction(false))}
            >
                Отмена
            </Button>
        </div>
    );
}

export default FooterForm;
