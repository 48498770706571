import { EPermissionsKeys, EPermissionsValues } from 'enums/permissions.enum';
import { ERoutes } from 'enums/routes.enum';
import { Accounting } from 'pages/accounting';
import { Deposits } from 'pages/accounting/deposits';
import { Transactions } from 'pages/accounting/transactions';
import { Withdraws } from 'pages/accounting/withdraws';
import ArchivePage from 'pages/archive';
import AutomationPage from 'pages/automation';
import ExternalFormPage from 'pages/external-form';
import { ImportIssuesPage } from 'pages/import-gsis';
import IssuePage from 'pages/issue';
import IssuesPage from 'pages/issues';
import MapPage from 'pages/map';
import PricePage from 'pages/price';
import PricesPage from 'pages/prices';
import ServicePage from 'pages/service';
import SupportPage from 'pages/support';
import WorkerPage from 'pages/worker';
import WorkersPage from 'pages/workers';
import { companyProfileSelector, profileSelector } from 'store/selectors/profileSelectors';

import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';

function MainRoutes() {
    const profile = useSelector(profileSelector);
    const companyProfile = useSelector(companyProfileSelector);

    const isOwner = profile?.role?.permissions?.[EPermissionsKeys.Accounting]?.includes(EPermissionsValues.Owner);
    const isCompanyOwner = companyProfile?.permissions?.[EPermissionsKeys.Accounting]?.includes(
        EPermissionsValues.Owner,
    );

    return (
        <Routes>
            <Route
                path={`${ERoutes.Issues}/`}
                element={<IssuesPage />}
            />
            <Route
                path={`${ERoutes.Issues}/:issueId`}
                element={<IssuePage />}
            />
            <Route
                path={`${ERoutes.Workers}/`}
                element={<WorkersPage />}
            />
            <Route
                path={`${ERoutes.Workers}/:workerId`}
                element={<WorkerPage />}
            />
            <Route
                path={`${ERoutes.Prices}/`}
                element={<PricesPage />}
            />
            <Route
                path={`${ERoutes.PriceServices}/:serviceId`}
                element={<ServicePage />}
            />
            <Route
                path={`${ERoutes.Price}/:priceId`}
                element={<PricePage />}
            />
            <Route
                path={`${ERoutes.Map}/`}
                element={<MapPage />}
            />
            <Route
                path={`${ERoutes.Test1}/`}
                element={<IssuesPage />}
            />
            {/* <Route path={`${ERoutes.Chats}/`} element={<ChatsPage />} />
			<Route path={`${ERoutes.Chats}/:chatId`} element={<ChatPage />} /> */}
            <Route
                path={`${ERoutes.Support}/`}
                element={<SupportPage />}
            />
            <Route
                path={`${ERoutes.Archive}/`}
                element={<ArchivePage />}
            />
            <Route
                path={`${ERoutes.Archive}/:issueId`}
                element={<IssuePage />}
            />
            <Route
                path={`${ERoutes.Archive}/worker/:workerId`}
                element={<WorkerPage />}
            />
            <Route
                path={`${ERoutes.Automation}/`}
                element={<AutomationPage />}
            />
            <Route
                path={`${ERoutes.ExternalForm}/`}
                element={<ExternalFormPage />}
            />
            {isOwner || isCompanyOwner ? (
                <Route
                    path={`${ERoutes.Accounting}/`}
                    element={<Accounting />}
                >
                    <Route
                        path={`${ERoutes.Deposits}`}
                        element={<Deposits />}
                    />
                    <Route
                        path={`${ERoutes.Withdraws}`}
                        element={<Withdraws />}
                    />
                    <Route
                        path={`${ERoutes.Transactions}`}
                        element={<Transactions />}
                    />
                </Route>
            ) : null}
            <Route
                path={`${ERoutes.ImportIssues}/:partnerId`}
                element={<ImportIssuesPage />}
            />
            <Route
                path="*"
                element={
                    <Navigate
                        replace
                        to={`${ERoutes.Issues}/`}
                    />
                }
            />
        </Routes>
    );
}

export default MainRoutes;
