import CloseIcon from '@mui/icons-material/Close';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import EditSharpIcon from '@mui/icons-material/EditSharp';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, Dialog, DialogContent, DialogTitle, Grid, IconButton } from '@mui/material';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// @ts-ignore // todo разобраться в чём проблема с типизацией в пакете она есть
import { useDebouncedCallback } from 'use-lodash-debounce';

import Autocomplete from '../../../../../components/inputs/Autocomplete';
import TextField from '../../../../../components/inputs/TextField';
import { IOption, TErrors } from '../../../../../interfaces';
import { getAddressesDictsThunk } from '../../../../../store/actions/dicts';
import { putIssueAddressThunk } from '../../../../../store/actions/issues';
import { useAppDispatch } from '../../../../../store/hooks';
import {
    addressesDictsSelector,
    addressesOptionsWithNameInValueDictsSelector,
} from '../../../../../store/selectors/dictsSelectors';
import { issueSelector } from '../../../../../store/selectors/issuesSelectors';
import defaultErrorCallback from '../../../../../utils/helpers/defaultErrorCallback';
import { getErrorString } from '../../../../../utils/helpers/getErrorString';
import './EditIssueAddress.scss';

interface IFormValues {
    address?: IOption<string> | null;
    apartment?: string | null;
    entrance?: string | null;
    floor?: string | null;
    // TODO: уточнить нужно ли это поле - инпута для него нет
    // house?: string | null;
    intercom?: string | null;
    lat?: number | null;
    long?: number | null;
    fias_id?: string | null;
}

function EditIssueAddress() {
    const dispatch = useAppDispatch();

    const issue = useSelector(issueSelector);
    const address = useSelector(addressesDictsSelector);
    const addressesOptions = useSelector(addressesOptionsWithNameInValueDictsSelector);

    const [isOpen, setIsOpen] = useState(false);
    const [formValues, setFormValues] = useState<IFormValues | null>(null);
    const [errors, setErrors] = useState<TErrors | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    const changeAddressInpDebounced = useDebouncedCallback(changeAddressInputHandler, 800);

    async function changeAddressInputHandler(val: string) {
        try {
            if (!val) return;
            await dispatch(getAddressesDictsThunk({ address: val }));
        } catch (err) {
            defaultErrorCallback({ errorMessage: getErrorString({ err }) });
        }
    }

    function handleClose() {
        setIsOpen(false);
    }

    useEffect(() => {
        setFormValues({
            address: issue?.address_parts?.address
                ? { label: issue.address_parts.address, value: issue.address_parts.address }
                : null,
            apartment: issue?.address_parts?.apartment,
            entrance: issue?.address_parts?.entrance,
            floor: issue?.address_parts?.floor,
            // house: issue?.address_parts?.house,
            intercom: issue?.address_parts?.intercom,
            lat: issue?.address_parts?.lat,
            long: issue?.address_parts?.long,
            fias_id: issue?.address_parts?.fias_id,
        });
        setErrors(null);
    }, [isOpen]);

    async function submitHandler() {
        if (!issue || !formValues) return;

        if (validator()) {
            try {
                setIsLoading(true);
                await dispatch(
                    putIssueAddressThunk({
                        issueId: issue.id,
                        newAddressParts: {
                            ...formValues,
                            address: formValues.address!.value,
                        },
                    }),
                );
                handleClose();
            } catch (err) {
                defaultErrorCallback({ errorMessage: getErrorString({ err }) });
            } finally {
                setIsLoading(false);
            }
        }
    }

    function changeAddressHandler(option: IOption<string> | null) {
        const currentOption = address?.find((opt) => opt.name === option?.label);
        setFormValues({
            ...(formValues || {}),
            address: option || null,
            lat: currentOption?.lat,
            long: currentOption?.long,
            fias_id: currentOption?.fias_id,
        });
    }

    function changeApartmentHandler(e: React.ChangeEvent<HTMLInputElement>) {
        setFormValues({ ...(formValues || {}), apartment: e.target.value || null });
    }

    function changeEntranceHandler(e: React.ChangeEvent<HTMLInputElement>) {
        setFormValues({ ...(formValues || {}), entrance: e.target.value || null });
    }

    function changeFloorHandler(e: React.ChangeEvent<HTMLInputElement>) {
        setFormValues({ ...(formValues || {}), floor: e.target.value || null });
    }

    function changeIntercomHandler(e: React.ChangeEvent<HTMLInputElement>) {
        setFormValues({ ...(formValues || {}), intercom: e.target.value || null });
    }

    const validator = useCallback(() => {
        const errs = {} as TErrors;

        if (!formValues?.address) {
            errs.address = { message: 'Обязательное поле' };
        }

        setErrors(errs);
        return !Object.values(errs).some((val) => val);
    }, [formValues]);

    useEffect(() => {
        if (errors) {
            validator();
        }
    }, [formValues]);

    return (
        <div className="val-prop-iss">
            {issue?.address}
            <IconButton
                className="edit-iss-prop-icon"
                onClick={() => setIsOpen(!isOpen)}
            >
                <EditSharpIcon />
            </IconButton>
            <Dialog
                open={isOpen}
                maxWidth="sm"
                fullWidth
                scroll="body"
                disableEscapeKeyDown
                onClose={handleClose}
            >
                <DialogTitle>
                    <Box className="edit-issue-address-dialog-title">
                        <div className="close-icon">
                            <CloseIcon onClick={handleClose} />
                        </div>
                        <DriveFileRenameOutlineOutlinedIcon className="title-icon" />{' '}
                        <div className="title-text">Изменение адреса вызова</div>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <div className="edit-issue-address-form-wrapper">
                        <Autocomplete
                            name="address"
                            inpPlaceholder="Город, улица, дом *"
                            loadingText="Загрузка..."
                            value={formValues?.address}
                            options={addressesOptions || []}
                            errors={errors}
                            onInpChange={changeAddressInpDebounced}
                            onChange={changeAddressHandler}
                            isOptionEqualToValue={() => true}
                        />
                        <Grid
                            container
                            spacing={1}
                        >
                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <TextField
                                    name="apartment"
                                    labelText="Квартира"
                                    fullWidth
                                    params={{ margin: 'normal' }}
                                    value={formValues?.apartment || ''}
                                    onChange={changeApartmentHandler}
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <TextField
                                    name="entrance"
                                    labelText="Подъезд"
                                    fullWidth
                                    params={{ margin: 'normal' }}
                                    value={formValues?.entrance || ''}
                                    onChange={changeEntranceHandler}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            spacing={1}
                        >
                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <TextField
                                    name="floor"
                                    labelText="Этаж"
                                    fullWidth
                                    params={{ margin: 'normal' }}
                                    value={formValues?.floor || ''}
                                    onChange={changeFloorHandler}
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <TextField
                                    name="intercom"
                                    labelText="Домофон"
                                    fullWidth
                                    params={{ margin: 'normal' }}
                                    value={formValues?.intercom || ''}
                                    onChange={changeIntercomHandler}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            spacing={1}
                        >
                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <TextField
                                    name="latitude"
                                    labelText="Широта"
                                    disabled
                                    fullWidth
                                    params={{ margin: 'normal' }}
                                    value={formValues?.lat || ''}
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <TextField
                                    name="longitude"
                                    labelText="Долгота"
                                    disabled
                                    fullWidth
                                    params={{ margin: 'normal' }}
                                    value={formValues?.long || ''}
                                />
                            </Grid>
                        </Grid>

                        <div className="buttons-wrapper">
                            <Button
                                variant="contained"
                                color="inherit"
                                onClick={handleClose}
                            >
                                Отмена
                            </Button>

                            <LoadingButton
                                variant="contained"
                                loading={isLoading}
                                onClick={submitHandler}
                            >
                                Сохранить
                            </LoadingButton>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default memo(EditIssueAddress);
